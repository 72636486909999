import {useLazyQuery, useQuery} from '@apollo/client';
import {Place} from '@mui/icons-material';
import {Box, Grid, Paper, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ReactComponent as CarroIcon} from '../../assets/icons/round-carro-icon.svg';
import {ReactComponent as OdometroIcon} from '../../assets/icons/round-odometro-icon.svg';
import ImagemContainer from '../../components/imagem-container-v2';
import {imagemAnuncioTipo} from '../../constants/enum-labels';
import ImagemModal from '../../containers/modais/imagem-modal';
import {
  FIND_ALL_ANUNCIOS_DA_CAMPANHA,
  FIND_ALL_CIDADES_CAMPANHA,
  FIND_ALL_IMAGENS_APROVADAS_ANUNCIO,
} from '../../graphql/queries';
import Data from '../../utils/data';
import Number from '../../utils/number';

const loadingPath = require('../../assets/images/loading-car.gif');

const ImagensCampanha = ({campanha, campanhaCidade}) => {
  const [cidades, setCidades] = useState(null);

  const [loadCidades, cidadesQuery] = useLazyQuery(FIND_ALL_CIDADES_CAMPANHA);

  useEffect(() => {
    if (!campanha) return;

    loadCidades({
      variables: {
        searchDTO: {
          campanhaId: campanha.id,
          campanhaCidadeId: campanhaCidade?.id,
        },
        pageable: {
          pageNumber: 0,
          pageSize: -1,
          orderBy: [
            {
              campo: 'cidade.nome',
              ordem: 'ASC',
            },
          ],
        },
      },
    });
  }, [campanha, campanhaCidade, loadCidades]);

  useEffect(() => {
    if (cidadesQuery.loading) return;

    setCidades(cidadesQuery.data?.cidades?.content || []);
  }, [cidadesQuery]);

  if (cidadesQuery.loading) {
    return (
      <Grid item sm={12} sx={styles.loadingBody}>
        <img
          src={loadingPath}
          alt="carregando..."
          style={{width: 100, margin: '5px 0'}}
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid
        container
        sx={styles.cidadesContainer}
        className="styled-scroll-transparent">
        {cidades?.map((item, index) => (
          <Grid key={item.id} container item xs={12}>
            <Grid item xs={12}>
              <Typography sx={styles.cidadeTitulo}>
                {item.cidade.nomeEUf}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AnunciosList key={item.id} index={index} campanhaCidade={item} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const AnunciosList = ({campanhaCidade}) => {
  const [anuncios, setAnuncios] = useState(null);

  const [loadAnuncios, anunciosResult] = useLazyQuery(
    FIND_ALL_ANUNCIOS_DA_CAMPANHA,
  );

  useEffect(() => {
    if (!campanhaCidade) return;

    loadAnuncios({
      variables: {
        searchDTO: {
          campanhaCidadeId: campanhaCidade?.id,
        },
        pageable: {
          pageNumber: 0,
          pageSize: -1,
          orderBy: [
            {
              campo: 'motorista.nome',
              ordem: 'ASC',
            },
          ],
        },
      },
    });
  }, [campanhaCidade, loadAnuncios]);

  useEffect(() => {
    if (anunciosResult?.data) {
      setAnuncios(anunciosResult.data.anuncios?.content || []);
    }
  }, [anunciosResult]);

  return (
    <Grid item sm={12}>
      {anuncios?.map((item, index) => (
        <MotoristaContainer key={item.id} index={index} anuncio={item} />
      ))}
    </Grid>
  );
};

const MotoristaContainer = ({anuncio, index, ...props}) => {
  const [imagemPerfil, setImagemPerfil] = useState(
    anuncio.motorista?.fotoPerfil || null,
  );
  const [imagensAnuncio, setImagensAnuncio] = useState(null);
  const [imagemModal, setImagemModal] = useState({
    visible: false,
    id: null,
    titulo: '',
  });

  const {veiculo, motorista} = anuncio;
  const dataInicio = moment(anuncio?.dataInicioVeiculacao).format('DD/MM/YYYY');
  const dataFim = Data.dataFormat(anuncio?.dataRemocao);

  const {data} = useQuery(FIND_ALL_IMAGENS_APROVADAS_ANUNCIO, {
    variables: {
      anuncio: {
        id: anuncio.id,
      },
    },
  });

  const kmRodado = anuncio?.kmRodado;

  useEffect(() => {
    if (!data) return;

    setImagensAnuncio(data?.imagensAnuncio);
  }, [data]);

  return (
    <Grid
      container
      spacing={2}
      className={
        index === 0
          ? 'impressao-motorista-container-first'
          : 'impressao-motorista-container'
      }
      style={{marginBottom: '25px', breakInside: 'avoid'}}>
      <Grid item container xs={12} spacing={0}>
        <Grid item xs={1}>
          <ImagemContainer
            sx={{
              imagemContainer: styles.fotoPerfilContainer,
              imagem: styles.fotoPerfil,
            }}
            image={imagemPerfil}
            onSetImagem={setImagemPerfil}
          />
        </Grid>
        <Grid item container xs={11} pl={2}>
          <Grid item xs={9}>
            <Typography sx={styles.motoristaDados}>
              Motorista: <b>{motorista?.nome}</b>
            </Typography>
            <Typography sx={styles.motoristaDados}>
              Veículo:{' '}
              <b>{`${veiculo?.modelo?.nome} ${veiculo?.anoFabricacao}/${veiculo?.anoModelo}`}</b>
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={styles.motoristaDados}>
                Data início veiculação: <b>{dataInicio}</b>
              </Typography>
              {dataFim && (
                <Typography sx={styles.motoristaDados} style={{marginLeft: 20}}>
                  Data remoção: <b>{dataFim}</b>
                </Typography>
              )}
            </Box>
            <Typography sx={styles.motoristaDados}>
              Km rodado:{' '}
              <b>
                {Number.format(Number.safeParseInt(kmRodado))}/
                {Number.format(anuncio.kmTotal)} Km
              </b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={styles.motoristaDados}>Placa</Typography>
            <Typography sx={styles.motoristaDados}>
              <b>{veiculo?.placa?.toUpperCase()}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {imagensAnuncio?.map((imagemAnuncio, index) => (
          <FotosContanier
            key={imagemAnuncio.id}
            index={index}
            imagemAnuncio={imagemAnuncio}
            anuncio={anuncio}
            onClickImagem={({id, titulo}) =>
              setImagemModal({visible: true, id, titulo})
            }
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <ImagemModal
          visible={imagemModal.visible}
          id={imagemModal.id}
          titulo={imagemModal.titulo}
          onClose={() => setImagemModal({visible: false, id: null, titulo: ''})}
        />
      </Grid>
    </Grid>
  );
};

const FotosContanier = ({
  imagemAnuncio,
  index,
  anuncio,
  onClickImagem,
  ...props
}) => {
  const [modeloAnuncio] = useState(anuncio.modeloAnuncio);
  const [imagemOdometro, setImagemOdometro] = useState(
    imagemAnuncio.imagemOdometro,
  );
  const [imagemDiagonalDireitaFrontal, setImagemDiagonalDireitaFrontal] =
    useState(imagemAnuncio.imagemDiagonalDireitaFrontal);
  const [imagemDiagonalEsquerdaTraseira, setImagemDiagonalEsquerdaTraseira] =
    useState(imagemAnuncio.imagemDiagonalEsquerdaTraseira);
  const [imagemLateralEsquerda, setImagemLateralEsquerda] = useState(
    imagemAnuncio.imagemLateralEsquerda,
  );
  const [imagemLateralDireita, setImagemLateralDireita] = useState(
    imagemAnuncio.imagemLateralDireita,
  );
  const [imagemFrente, setImagemFrente] = useState(imagemAnuncio.imagemFrente);
  const [imagemTraseira, setImagemTraseira] = useState(
    imagemAnuncio.imagemTraseira,
  );
  const [imagemInterna, setImagemInterna] = useState(
    imagemAnuncio.imagemInterna,
  );
  const [screenX, setScreenX] = useState(null);

  const dataEnvio =
    index === 0 && imagemAnuncio.tipo === 'INSTALACAO'
      ? Data.dataFormat(anuncio?.dataInstalacao, 'DD/MM/YYYY HH:mm')
      : Data.dataFormat(imagemAnuncio.data, 'DD/MM/YYYY HH:mm');

  const isImagemLateral =
    imagemAnuncio.imagemLateralEsquerda && imagemAnuncio.imagemLateralDireita;

  const handleMouseDown = (e) => {
    setScreenX(e.screenX);
  };

  const handleClickImagem = (e, info) => {
    const delta = Math.abs(e.screenX - screenX);

    if (delta > 10) {
      e.preventDefault();
    } else {
      onClickImagem && onClickImagem(info);
    }

    setScreenX(0);
  };

  const getImagemContainerClass = () => {
    if (isImagemLateral)
      return {
        imagem: styles.imagensLateralVeiculo,
        imagemContainer: styles.imagensContainer,
      };

    return {
      imagem: styles.imagensVeiculo,
      imagemContainer: styles.imagensContainer,
    };
  };

  return (
    <Paper sx={styles.box}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            <Grid item xs={4}>
              <Typography sx={styles.label}>
                <CarroIcon style={styles.labelIcon} /> Envio:{' '}
                <Typography component={'span'} sx={styles.labelValue}>
                  {dataEnvio} - {imagemAnuncioTipo[imagemAnuncio.tipo]}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={styles.label}>
                <OdometroIcon style={styles.labelIcon} /> Odômetro:{' '}
                <Typography component={'span'} sx={styles.labelValue}>
                  {(imagemAnuncio.valorOdometro &&
                    `${Number.format(imagemAnuncio.valorOdometro)} Km`) ||
                    ' - '}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {imagemAnuncio.latitude && imagemAnuncio.longitude && (
                <Typography sx={styles.label}>
                  <Place style={styles.labelIcon} /> Localização:{' '}
                  <Typography component={'span'} sx={styles.labelValue}>
                    {`${imagemAnuncio.latitude}, ${imagemAnuncio.longitude}`}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                {imagemAnuncio &&
                  anuncio?.motorista?.id !== imagemAnuncio.motorista?.id && (
                    <Typography sx={styles.substituidoLabel}>
                      Motorista substituído
                    </Typography>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {imagemAnuncio && (
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row !important">
              {imagemLateralEsquerda &&
                (modeloAnuncio.laterais || modeloAnuncio.lateraisRecorte) && (
                  <Box sx={styles.imagemColumn}>
                    <ImagemContainer
                      sx={getImagemContainerClass()}
                      image={imagemLateralEsquerda}
                      onSetImagem={setImagemLateralEsquerda}
                      onClickImage={handleClickImagem}
                      onMouseDown={handleMouseDown}
                      label={`Imagem lateral esquerda`}
                      style={{objectFit: 'cover'}}
                    />
                  </Box>
                )}
              {imagemLateralDireita &&
                (modeloAnuncio.laterais || modeloAnuncio.lateraisRecorte) && (
                  <Box sx={styles.imagemColumn}>
                    <ImagemContainer
                      sx={getImagemContainerClass()}
                      image={imagemLateralDireita}
                      onSetImagem={setImagemLateralDireita}
                      onClickImage={handleClickImagem}
                      onMouseDown={handleMouseDown}
                      label={`Imagem lateral direita`}
                    />
                  </Box>
                )}
              {imagemDiagonalDireitaFrontal && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    sx={getImagemContainerClass()}
                    image={imagemDiagonalDireitaFrontal}
                    onSetImagem={setImagemDiagonalDireitaFrontal}
                    onClickImage={handleClickImagem}
                    onMouseDown={handleMouseDown}
                    label={`Imagem diagonal direita frontal`}
                  />
                </Box>
              )}
              {imagemDiagonalEsquerdaTraseira && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    sx={getImagemContainerClass()}
                    image={imagemDiagonalEsquerdaTraseira}
                    onSetImagem={setImagemDiagonalEsquerdaTraseira}
                    onClickImage={handleClickImagem}
                    onMouseDown={handleMouseDown}
                    label={`Imagem diagonal esquerda traseira`}
                  />
                </Box>
              )}
              {imagemTraseira && modeloAnuncio.traseira && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    sx={getImagemContainerClass()}
                    image={imagemTraseira}
                    onSetImagem={setImagemTraseira}
                    onClickImage={handleClickImagem}
                    onMouseDown={handleMouseDown}
                    label={`Imagem traseira`}
                  />
                </Box>
              )}
              {imagemFrente && modeloAnuncio.frente && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    sx={getImagemContainerClass()}
                    image={imagemFrente}
                    onSetImagem={setImagemFrente}
                    onClickImage={handleClickImagem}
                    onMouseDown={handleMouseDown}
                    label={`Imagem frente`}
                  />
                </Box>
              )}
              {imagemInterna && modeloAnuncio.interno && (
                <Box sx={styles.imagemColumn}>
                  <ImagemContainer
                    sx={getImagemContainerClass()}
                    image={imagemInterna}
                    onSetImagem={setImagemInterna}
                    onClickImage={handleClickImagem}
                    onMouseDown={handleMouseDown}
                    label={`Imagem interna`}
                  />
                </Box>
              )}
              <Box sx={styles.imagemColumn}>
                <ImagemContainer
                  sx={getImagemContainerClass()}
                  image={imagemOdometro}
                  onSetImagem={setImagemOdometro}
                  onClickImage={handleClickImagem}
                  onMouseDown={handleMouseDown}
                  label={`Imagem odômetro`}
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const styles = {
  container: {
    height: '100%',
  },
  cidadesContainer: {
    backgroundColor: (theme) => theme.palette.paterns.white,
    padding: '16px',
    height: '100%',
    overflow: 'auto',
    borderRadius: '6px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    padding: '4px 4px 18px 4px',
    marginBottom: '15px',
  },
  fotoPerfil: {
    borderRadius: 100,
    maxWidth: '75px !important',
    maxHeight: '75px !important',
    minWidth: '75px !important',
    minHeight: '75px !important',
    objectFit: 'cover',
    cursor: 'default',
  },
  fotoPerfilContainer: {
    backgroundColor: '#CDCDCD',
    borderRadius: 100,
    maxWidth: '75px !important',
    maxHeight: '75px !important',
    minWidth: '75px !important',
    minHeight: '75px !important',
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
  },
  motoristaDados: {
    fontSize: 14,
  },
  imagensVeiculo: {
    borderRadius: '16px',
  },
  imagensLateralVeiculo: {
    borderRadius: '16px',
    objectFit: 'cover',
  },
  imagensContainer: {
    height: '9vw',
    width: '100%',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
    background: '#EFEFEF',
  },
  imagemColumn: {
    padding: '0 10px',
    width: '-webkit-fill-available',
    maxWidth: '33.33%',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 50px 0 5px',
    padding: '5px 0',
    borderRadius: '100px',
    fontSize: '14px',
    color: '#657496',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  labelIcon: {
    height: 25,
    marginRight: '10px',
    fill: '#657496',
  },
  labelValue: {
    fontWeight: '700',
    marginLeft: '8px',
    fontSize: '14px',
  },
  loadingBody: {
    textAlign: 'center',
    padding: '50px 0',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  substituidoLabel: {
    display: 'flex',
    zIndex: 100,
    height: 20,
    background: 'red',
    borderRadius: '20px 0 0 20px',
    padding: '0 15px',
    color: 'white',
    fontWeight: '500',
    fontSize: 12,
    marginLeft: 'auto',
    marginRight: '-4px',
    marginTop: '-4px',
    borderTopRightRadius: '14px',
  },
  cidadeTitulo: {
    background: '#e5e5e5',
    padding: '8px',
    paddingLeft: '24px',
    borderRadius: '0px 35px 35px 0px',
    margin: '0px 0px 24px -16px',
    fontWeight: '700',
  },
};

export default ImagensCampanha;

import {useQuery} from '@apollo/client';
import {Box, Grid} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import InfoWindow from '../../google/InfoWindow';
import Map from '../../google/Map';
import Marker from '../../google/Marker';
import TransitLayer from '../../google/TransitLayer';
import {FIND_ULTIMAS_LOCALIZACOES_MOTORISTA_CAMPANHA} from '../../graphql/queries';
import {getImageAuth} from '../../utils/get-image';
import {centroBrasil} from '../../utils/get-places';

const MapaDetalhesMotorista = ({campanhaCidade, anuncio}) => {
  const coordenadasCidade = {
    lat: campanhaCidade?.cidade?.latitude || centroBrasil.lat,
    lng: campanhaCidade?.cidade?.longitude || centroBrasil.lng,
  };
  const [centerMapLatLng, setCenterMapLatLng] = useState(coordenadasCidade);
  const [transitLayerEnabled] = useState(false);
  const [localizacoes, setLocalizacoes] = useState([]);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    motorista: null,
    marker: null,
    map: null,
  });

  const localizacoesQuery = useQuery(
    FIND_ULTIMAS_LOCALIZACOES_MOTORISTA_CAMPANHA,
    {
      variables: {
        anuncio: {
          id: anuncio.id,
        },
      },
    },
  );

  const motoristaInfoWindow =
    infoWindowSettings && infoWindowSettings.motorista
      ? infoWindowSettings.motorista
      : null;

  const formatarDataLocalizacao = (data) =>
    `${moment(data).format('DD/MM/YYYY')} às ${moment(data).format(
      'HH',
    )}h${moment(data).format('mm')}`;

  useEffect(() => {
    if (localizacoesQuery.loading || localizacoesQuery.error) {
      setLocalizacoes([]);
      return;
    }

    setLocalizacoes(localizacoesQuery.data?.localizacoes || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizacoesQuery]);

  useEffect(() => {
    if (localizacoes) {
      const centerMap = () => {
        const localizacao = localizacoes.find((l) => l.lat || l.latitude);
        if (localizacao && localizacao.latitude && localizacao.longitude) {
          return {lat: localizacao.latitude, lng: localizacao.longitude};
        } else if (localizacao && localizacao.lat && localizacao.lng) {
          return {lat: localizacao.lat, lng: localizacao.lng};
        }
        return centerMapLatLng;
      };
      const newCenterMapLatLng = centerMap();
      if (
        centerMapLatLng.lat !== newCenterMapLatLng.lat &&
        centerMapLatLng.lng !== newCenterMapLatLng.lng
      ) {
        setCenterMapLatLng(centerMap());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizacoes]);

  return (
    <Grid container item xs={12} height={'100%'}>
      <Map
        zoom={13}
        center={centerMapLatLng}
        locations={localizacoes}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }}>
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div className="iw-condutor-container">
            <div className="iw-condutor-avatar">
              <Box
                component="img"
                src={getImageAuth(motoristaInfoWindow?.fotoPerfil)}
                onError={() =>
                  setInfoWindowSettings({
                    ...infoWindowSettings,
                    motorista: {
                      ...motoristaInfoWindow,
                      fotoPerfil: null,
                    },
                  })
                }
                alt="avatar"
                sx={styles.avatar}
              />
            </div>
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <label>Condutor</label>
                <p>{motoristaInfoWindow && motoristaInfoWindow.nome}</p>
              </div>
              <div className="iw-condutor-info-posicao">
                <label>Última posição</label>
                <p>
                  {motoristaInfoWindow && motoristaInfoWindow.data
                    ? formatarDataLocalizacao(motoristaInfoWindow.data)
                    : null}
                </p>
              </div>
            </div>
          </div>
        </InfoWindow>
        {localizacoes &&
          localizacoes.map((localizacao, index) => {
            const {monitoramento = {}} = localizacao;
            const {motorista = {}} = monitoramento;
            return (
              <Marker
                key={index}
                active={false}
                position={{
                  lat: localizacao.latitude,
                  lng: localizacao.longitude,
                }}
                events={{
                  onMouseOver: (map, marker) => {
                    setInfoWindowSettings({
                      visible: true,
                      marker,
                      map,
                      motorista: {
                        ...motorista,
                        data: localizacao.data || null,
                      },
                    });
                  },
                  onMouseOut: () => {
                    setInfoWindowSettings({
                      visible: false,
                      motorista: null,
                      marker: null,
                      map: null,
                    });
                  },
                }}
              />
            );
          })}
      </Map>
    </Grid>
  );
};

const styles = {
  avatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
};

export default MapaDetalhesMotorista;

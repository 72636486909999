import React from 'react';
import moment from 'moment';
import {useMutation} from '@apollo/client';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {Grid, Link, Typography} from '@mui/material';
import {Facebook, Instagram} from '@mui/icons-material';
import {ButtonComponent} from '../components/mui-button';
import {CRIAR_EMAIL_CONTATO} from '../graphql/mutation';
import InputV2Formik from '../components/input-v2/mui-input-v2-formik';
import SelectFormik from '../components/select/mui-select-formik';
import TextareaFormik from '../components/textarea/mui-textarea-formik';
import {toastNotification} from '../components/toastify';

const tiposDuvidas = [
  {value: 'CRITICA', label: 'Crítica'},
  {value: 'DUVIDA', label: 'Dúvida'},
  {value: 'SUGESTAO', label: 'Sugestão'},
];

export const ContatoContainer = () => {
  const [enviarEmail, {loading}] = useMutation(CRIAR_EMAIL_CONTATO);

  const handleSubmit = (values, {resetForm}) => {
    if (loading) return;

    enviarEmail({
      variables: {...values, tipoDuvida: values.tipoDuvida.value},
    })
      .then(() => {
        resetForm();
        toastNotification({message: 'Mensagem enviada!', type: 'success'});
      })
      .catch(error => toastNotification({message: error.message, type: 'error'}));
  };

  return (
    <Grid container sx={styles.container}>
      <Formik
        validationSchema={ContatoScheme}
        initialValues={{
          titulo: '',
          telefone: '',
          email: '',
          tipoDuvida: {value: 'DUVIDA', label: 'Dúvida'},
          mensagem: '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({values, handleSubmit, resetForm}) => (
          <Grid container spacing={2} padding={2} className='styled-scroll-transparent' sx={styles.formContainer}>
            <Grid container item xs={12} spacing={2} justifyContent={'center'}>
              <Grid item xs={12}>
                <Field
                  component={InputV2Formik}
                  name="titulo"
                  label="Título"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={SelectFormik}
                  name="tipoDuvida"
                  label="Dúvidas"
                  options={tiposDuvidas}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={InputV2Formik}
                  name="telefone"
                  label="Telefone"
                  mask="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={InputV2Formik}
                  name="email"
                  label="E-mail"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextareaFormik}
                  name="mensagem"
                  label="Mensagem"
                  rows={4}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                type="button"
                disabled={loading}
                value={'Enviar'}
                onClick={() => handleSubmit(values, resetForm)}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
      <Grid item xs={12} sx={styles.footerContainer}>
        <Grid container sx={styles.linkContainer}>
          <Link
            href="https://web.facebook.com/kmmidiaooh.br/"
            target="_blank"
            sx={styles.socialLink}
          >
            <Facebook sx={styles.socialIcon} />
          </Link>
          <Link
            href="https://www.instagram.com/kmmidiaooh.br/?hl=pt-br"
            target="_blank"
            sx={styles.socialLink}
          >
            <Instagram sx={styles.socialIcon} />
          </Link>
        </Grid>
        <Typography sx={styles.copyright}>
          &copy; Copyright {moment().format('Y')}, KM Mídia
          <br />
          CNPJ: 33.793.168/0001-50. Todos os direitos reservados.
        </Typography>
      </Grid>
    </Grid>
  );
};

const ContatoScheme = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Campo não informado'),
  titulo: Yup.string()
    .min(6, 'Título muito curto')
    .required('Campo não informado'),
  tipoDuvida: Yup.string().required('Tipo não selecionado.'),
  telefone: Yup.string()
    .min(15, 'Telefone inválido')
    .max(16, 'Telefone inválido')
    .required('Campo não informado'),
  mensagem: Yup.string()
    .min(10, 'Mensagem muito curta')
    .required('Mensagem não informada'),
});

const styles = {
  container: {
    justifyContent: 'center',
    alignContent: 'space-between',
    height: theme => `calc(100vh - ${theme.toolbarHeight})`,
    paddingTop: '16px',
  },
  formContainer: {
    alignContent: 'space-between',
    height: 'calc(100% - 92px)',
    overflow: 'auto',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: '1px solid #cfd4df',
    padding: '8px 0',
    height: '92px'
  },
  copyright: {
    fontSize: '10px',
    color: '#657496',
    textAlign: 'center',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  socialIcon: {
    fill: '#657496b0'
  },
  socialLink: {
    cursor: 'pointer',
    margin: '0 8px'
  }
}
import React, {useEffect, useState} from 'react';
import {CircularProgress, Grid, Typography} from '@mui/material';
import {DateRange} from '@mui/icons-material';
import {HeatMapGrid} from 'react-grid-heatmap'
import Number from '../../utils/number';

const labelsStyle = {
  color: '#657496',
  fontFamily: 'IBM Plex Sans',
  fontWeight: '700',
  fontSize: 10,
  textAlign: 'center'
};

const labelHorarios = ['12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM',
  '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM'];
const labelDias = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

const toWeekDay = (dia) => {
  switch (dia) {
    case 'DOM': return 7;
    case 'SEG': return 1;
    case 'TER': return 2;
    case 'QUA': return 3;
    case 'QUI': return 4;
    case 'SEX': return 5;
    case 'SÁB': return 6;
    default: return '';
  }
}

const GraficoHeatmap = ({heatmap, titulo, cellHeight = 55, cellValueColor = '#0000', loading, impressaoStyle = {}}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    if (!heatmap?.length > 0) return;

    setData(labelDias.map((dia) => {
      const dados = new Array(labelHorarios.length).fill(0);
      heatmap.filter(i => i.diaSemana === toWeekDay(dia)).map(i => dados[i.hora] = Number.safeParseInt(i.valor));
      return dados;
    }));
  }, [heatmap])

  return (
    <Grid sx={styles.container}>
      <Grid container item direction='row' alignItems='center'>
        <DateRange sx={styles.totaisIcon} />
        <Typography sx={styles.totaisTitle}>{titulo} por horário do dia</Typography>
      </Grid>
      <Grid sx={styles.graficoContainer}>
        {loading && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress sx={styles.loading} />
          </Grid>
        )}
        <HeatMapGrid
          data={data}
          xLabels={labelHorarios}
          yLabels={labelDias}
          square={true}
          cellRender={(x, y, value) => (
            <div title={`${Number.format(value)}`}>{value}</div>
          )}
          cellStyle={(_x, _y, ratio) => ({
            background: `rgb(3, 22, 99, ${ratio})`,
            fontSize: '8px',
            color: cellValueColor,
            cursor: 'default',
            ...impressaoStyle
          })}
          xLabelsStyle={() => ({...labelsStyle, ...impressaoStyle, minWidth: `${cellHeight / 24}vw`})}
          yLabelsStyle={() => labelsStyle}
          cellHeight={`${cellHeight / 24}vw`}
        />
      </Grid>
    </Grid>
  )
};

const styles = {
  loading: {
    width: '30px !important',
    height: '30px !important',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '0px 2px 12px 0px #0000001A',
    marginBottom: '16px',
  },
  totaisIcon: {
    color: '#031663',
    height: '20px',
    width: '20px',
    margin: '0 7px',
  },
  totaisTitle: {
    color: '#031663',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  graficoContainer: {
    position: 'relative',
    padding: '20px 0 10px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      maxWidth: '100% !important',
    },
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff6',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}

export default GraficoHeatmap;
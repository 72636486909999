import {useQuery} from '@apollo/client';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import TableCellOrdered from '../../components/table-cell-ordered';
import {FIND_ALL_ANUNCIOS_DA_CAMPANHA} from '../../graphql/queries';
import Number from '../../utils/number';
import StringUtils from '../../utils/string';

const columns = [
  {
    id: 'nome',
    label: 'Nome',
    campo: 'motorista.nome',
    format: ({motorista}) => motorista?.nome,
  },
  {
    id: 'veiculo',
    label: 'Veículo',
    calculado: true,
    format: ({motorista}) => {
      const veiculoAtual = motorista.veiculoAtual;
      return `${veiculoAtual?.modelo?.marca?.nome || '-'} ${
        veiculoAtual?.modelo?.nome || '-'
      } ${veiculoAtual?.anoFabricacao || '-'}/${
        veiculoAtual?.anoModelo || '-'
      }`;
    },
  },
  {
    id: 'cidade',
    label: 'Cidade',
    campo: 'campanha.cidade.nome',
    format: ({campanha}) => campanha?.cidade?.nomeEUf,
  },
  {
    id: 'placa',
    label: 'Placa',
    campo: 'motorista.veiculoAtual.placa',
    format: ({motorista}) =>
      StringUtils.formatPlaca(motorista?.veiculoAtual?.placa),
  },
  {
    id: 'kmRodado',
    label: 'Km rodado',
    format: ({kmRodado}) => Number.format(Number.safeParseInt(kmRodado)),
  },
  {
    id: 'kmRodadoDia',
    label: 'Km rodado no dia',
    calculado: true,
    format: ({kmRodadoDia}) => Number.format(Number.safeParseInt(kmRodadoDia)),
  },
];

const ListaMotoristas = ({campanha, campanhaCidade, selecionarAnuncio}) => {
  const [orderBy, setOrderBy] = useState({campo: 'motorista.nome', asc: true});
  const [page, setPage] = useState(0);
  const [pageSize] = useState(20);
  const [motoristas, setMotoristas] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const anunciosQuery = useQuery(FIND_ALL_ANUNCIOS_DA_CAMPANHA, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize,
        sortField: orderBy.campo,
        sortDir: orderBy.asc ? 'ASC' : 'DESC',
      },
      searchDTO: {
        campanhaId: campanha?.id,
        campanhaCidadeId: campanhaCidade?.id,
      },
    },
  });

  useEffect(() => {
    if (anunciosQuery.loading || anunciosQuery.error) return;

    setTotalElements(anunciosQuery.data.anuncios?.totalElements || 0);
    setMotoristas(anunciosQuery.data?.anuncios?.content || []);
  }, [anunciosQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container item xs={12} sx={styles.container}>
      <Grid item xs={12} sx={styles.tituloContainer}>
        <Typography sx={styles.titulo}>Motoristas</Typography>
      </Grid>
      <Grid item xs={12} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCellOrdered
                  key={column.id}
                  column={column}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {motoristas.map((motorista) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={motorista.id}
                onClick={() => selecionarAnuncio(motorista)}
                sx={{cursor: "pointer"}}>
                {columns.map((column) => {
                  const value = motorista[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format ? column.format(motorista) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} sx={styles.paginacaoContainer}>
        <TablePagination
          component="div"
          count={totalElements}
          rowsPerPageOptions={[]}
          rowsPerPage={pageSize}
          page={page}
          backIconButtonProps={{
            'aria-label': 'página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'próxima página',
          }}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} de ${count}`
          }
          onPageChange={handleChangePage}
        />
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
  },
  tituloContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
  },
  titulo: {
    fontFamily: 'AktivGrotesk-Bold',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '47px',
  },
  tableContainer: {
    overflow: 'auto',
    height: 'calc(100% - 95px)',
  },
  paginacaoContainer: {
    height: 'fit-content',
  },
};

export default ListaMotoristas;

import React from 'react';
import Textarea from './mui-textarea';

const TextareaFormik = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <Textarea
      {...field}
      {...props}
      value={field.value || ''}
      error={errors[field.name] && touched[field.name] ? true : false}
      mensagemSuporte={
        errors[field.name] && touched[field.name] && errors[field.name]
      }
    />
  );
};

export default TextareaFormik;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  campanhaSelected: undefined,
  cidadeSelected: undefined
};

const acaoSetCampanhaSelected = (state, action) => {
  state.campanhaSelected = action.payload;
};

const acaoSetCidadeSelected = (state, action) => {
  state.cidadeSelected = action.payload;
};

const resumeSlice = createSlice({
  name: 'toolbar',
  initialState: initialState,
  reducers: {
    setCampanhaSelected: acaoSetCampanhaSelected,
    setCidadeSelected: acaoSetCidadeSelected,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setCampanhaSelected, setCidadeSelected} = actions;

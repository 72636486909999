import React from 'react';
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material';

export const TextAreaV2 = ({
  label,
  mensagemSuporte,
  className,
  classInput = 'input',
  value = '',
  onChange,
  ...props
}) => {
  return (
    <FormControl
      fullWidth
      style={{marginBottom: '15px'}}
      size="small"
      className="input-v2"
    >
      <InputLabel
        htmlFor="inputV2"
        size="small"
        id="input-label-v2"
        shrink={!!value || undefined}
        className={`${props.error ? 'error' : ''}`}
        disabled={props.disabled}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="input-text-v2"
        type='text'
        label={label || ""}
        disabled={props.disabled}
        className={` ${value ? 'withValue' : ''}${props.error ? '-error' : ''}`}
        rows={props.row || 2}
        notched={!!value || undefined}
        endAdornment={props.endAdornment}
        value={value}
        multiline
        onChange={onChange}
        {...props}
      />
      {props.error && (
        <FormHelperText
          sx={{color: (theme) => theme.palette.cancel.main}}
          id="support-message-id"
        >
          {mensagemSuporte}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextAreaV2;
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  periodoSelecionado: undefined, 
  isPorPeriodos: true
};

const acaoSetPeriodosSelecionado = (state, action) => {
  state.periodoSelecionado = action.payload;
};

const acaoSetIsPorPeriodo = (state, action) => {
  state.isPorPeriodos = action.payload;
};

const resumeSlice = createSlice({
  name: 'periodos',
  initialState: initialState,
  reducers: {
    setPeriodosSelecionado: acaoSetPeriodosSelecionado,
    setIsPorPeriodo: acaoSetIsPorPeriodo,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setPeriodosSelecionado, setIsPorPeriodo} = actions;

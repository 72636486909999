import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import {useLazyQuery} from '@apollo/client';
import {PrintRounded} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {
  KM_RODADO_HEATMAP_CAMPANHA,
  KM_RODADO_PERIODOS_CAMPANHA,
  TOTAL_KM_RODADO_CAMPANHA,
} from '../../graphql/queries';
import Data from '../../utils/data';
import ImpressaoQuilometros from './impressao-quilometros';
import {RoundTooltipButton} from '../../components/mui-button';
import GraficoBarras from '../../components/graficos/grafico-barras';
import GraficoDiaSemana from '../../components/graficos/grafico-dia-semana';
import GraficoHeatmap from '../../components/graficos/grafico-heatmap';
import GraficoPeriodoDia from '../../components/graficos/grafico-periodo-dia';
import GraficoTotais from '../../components/graficos/grafico-totais';
import Number from '../../utils/number';

const pageStyle = `
  @page { 
    size: A4; 
    margin: 0mm 5mm 0mm 5mm;
  }

  @media print {
    .chartjs-size-monitor{
      position: fixed !important;
    }
    html, body, #root {
      width: 297mm;
      height: 420mm;
      background: #fff !important;
    }
  }

  @media all {
    .report-hidden {
      display: block !important;
    }
    div {
      -webkit-print-color-adjust: exact; 
    },
  }
`;

const KmRodado = () => {
  const {campanhaSelected: campanha, cidadeSelected: campanhaCidade} = useSelector((store) => store.Toolbar);
  const {periodoSelecionado, isPorPeriodos} = useSelector((store) => store.Periodos);

  const componentRef = useRef();

  const [loading, setLoading] = useState(false);
  const [totalKmRodado, setTotalKmRodado] = useState(0);
  const [kmPeriodos, setKmPeriodos] = useState(undefined);
  const [kmDiaSemana, setKmDiaSemana] = useState([]);
  const [kmPeriodoDia, setKmPeriodoDia] = useState([]);
  const [kmRodadoHeatmap, setKmRodadoHeatmap] = useState([]);

  const {intervalos, meses, periodos} = Data.getPeriodosCampanha(campanha, isPorPeriodos, periodoSelecionado);

  const [loadTotais, totaisQuery] = useLazyQuery(TOTAL_KM_RODADO_CAMPANHA);
  const [loadKmRodadoHeatmap, kmRodadoHeatmapQuery] = useLazyQuery(KM_RODADO_HEATMAP_CAMPANHA);
  const [loadPeriodos, periodosQuery] = useLazyQuery(KM_RODADO_PERIODOS_CAMPANHA);

  useEffect(() => {
    if (!campanha) return;

    setKmPeriodos(undefined);

    const dataInicio = periodos[periodoSelecionado]?.inicio?.format('YYYY-MM-DD');
    const dataFim = periodos[periodoSelecionado]?.fim?.format('YYYY-MM-DD');

    loadTotais({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id ? {
          id: campanhaCidade?.id
        } : null,
        dataInicio,
        dataFim
      },
    });

    loadKmRodadoHeatmap({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id ? {
          id: campanhaCidade?.id
        } : null,
        dataInicio,
        dataFim
      },
    });

    loadPeriodos({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id ? {
          id: campanhaCidade?.id
        } : null,
        intervalos: intervalos,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, campanhaCidade, isPorPeriodos, periodoSelecionado]);

  useEffect(() => {
    if (periodosQuery.loading) return;

    setKmPeriodos(periodosQuery.data?.kmRodado);
  }, [periodosQuery]);

  useEffect(() => {
    if (totaisQuery.loading) return;

    setTotalKmRodado(parseInt(totaisQuery.data?.kmTotal || 0));
    setKmDiaSemana(totaisQuery.data?.kmDiaSemana);
    setKmPeriodoDia(totaisQuery.data?.kmPeriodoDia);
  }, [totaisQuery]);

  useEffect(() => {
    if (kmRodadoHeatmapQuery.loading) return;

    setKmRodadoHeatmap(kmRodadoHeatmapQuery.data?.kmRodadoHeatmap || []);
  }, [kmRodadoHeatmapQuery]);

  return (
    <Grid item xs={12} sx={styles.graphsContainer}>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Quilometros - ${campanha?.anunciante?.nomeFantasia}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintRounded />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <KmRodadoDashboard
        campanha={campanha}
        campanhaCidade={campanhaCidade}
        kmTotal={totalKmRodado}
        kmPeriodos={kmPeriodos}
        periodoSelecionado={periodos[periodoSelecionado]}
        meses={meses}
        kmDiaSemana={kmDiaSemana}
        kmPeriodoDia={kmPeriodoDia}
        kmRodadoHeatmap={kmRodadoHeatmap}
        periodosLoading={periodosQuery.loading}
        kmPeriodoDiaLoading={totaisQuery.loading}
        kmRodadoHeatmapLoading={kmRodadoHeatmapQuery.loading}
      />
      <div style={{overflow: 'hidden', height: 0}}>
        <Grid style={{background: '#fff'}} ref={componentRef}>
          <ImpressaoQuilometros
            campanha={campanha}
            campanhaCidade={campanhaCidade}
            periodos={periodos}
            kmTotal={totalKmRodado}
            kmPeriodos={kmPeriodos}
            periodoSelecionado={periodos[periodoSelecionado]}
            meses={meses}
            kmDiaSemana={kmDiaSemana}
            kmPeriodoDia={kmPeriodoDia}
            kmRodadoHeatmap={kmRodadoHeatmap}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const KmRodadoDashboard = ({
  campanha,
  campanhaCidade,
  kmTotal,
  periodoSelecionado,
  kmPeriodos,
  meses,
  kmDiaSemana,
  kmPeriodoDia,
  kmRodadoHeatmap,
  periodosLoading,
  kmPeriodoDiaLoading,
  kmRodadoHeatmapLoading
}) => {
  const [kmMediaPeriodo, setKmMediaPeriodo] = useState(0);
  const [kmMediaDia, setKmMediaDia] = useState(0);

  useEffect(() => {
    if (!campanha) return;

    const dias = Data.calculaDiasNoPeriodo(campanha.dataInicioVeiculacao, campanha.diasPeriodo, campanha.quantidadeMeses, periodoSelecionado?.index);
    const periodos = periodoSelecionado ? 1 : Data.periodoDaCampanha(campanha.dataInicioVeiculacao, campanha.diasPeriodo, campanha.quantidadeMeses);
    setKmMediaPeriodo(kmTotal / periodos / (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos));
    setKmMediaDia(kmTotal / (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos) / (dias || 1));
  }, [campanha, campanhaCidade, periodoSelecionado, kmTotal]);

  return (
    <Grid container>
      <GraficoTotais
        titulo='Quilometragem rodada'
        totais={[
          {label: 'Total', value: `${Number.format(Number.safeParseInt(kmTotal))} km`},
          {label: 'Média / Veículo / Período', value: `${Number.format(Number.safeParseInt(kmMediaPeriodo))} km`},
          {label: 'Média / Veículo / Dia', value: `${Number.format(Number.safeParseInt(kmMediaDia))} km`},
        ]}
      />
      <GraficoBarras campanha={campanha} meses={meses} periodos={kmPeriodos} periodoSelecionado={periodoSelecionado} titulo='Quilometragem' loading={periodosLoading} />
      <GraficoDiaSemana valorTotal={kmTotal} dias={kmDiaSemana} titulo='Quilometragem' unidadeMedida='Km' loading={kmPeriodoDiaLoading} />
      <GraficoPeriodoDia campanha={campanha} periodos={kmPeriodoDia} valorTotal={kmTotal} loading={kmPeriodoDiaLoading} titulo='Quilometragem' unidadeMedida='Km' />
      <GraficoHeatmap campanha={campanha} heatmap={kmRodadoHeatmap} loading={kmRodadoHeatmapLoading} titulo='Quilometragem' />
    </Grid>
  );
};

const styles = {
  graphsContainer: {
    overflowY: 'auto',
    padding: '16px',
    height: theme => theme.contentHeightMd,
  },
};

export default KmRodado;

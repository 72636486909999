import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom"
import {useLazyQuery} from "@apollo/client";
import {CircularProgress, Grid, Paper, Tooltip, Typography, Zoom} from "@mui/material";
import {ChevronRight, InfoRounded} from "@mui/icons-material";
import Number from "../../utils/number";
import {PASSAGEIROS_ROUTE} from "../../constants/routes";
import {TOTAIS_CAMPANHA_HOME} from "../../graphql/queries";

const PassageirosCampanha = ({campanha, campanhaCidade}) => {
  const navigate = useNavigate();

  const [passageiros, setPassageiros] = useState(0);

  const [loadTotais, totalQuery] = useLazyQuery(TOTAIS_CAMPANHA_HOME, {
    variables: {
      campanha: {
        id: campanha?.id,
      },
      campanhaCidade: campanhaCidade?.id ? {
        id: campanhaCidade?.id
      } : null,
    }
  });

  useEffect(() => {
    if (!campanha) return;
    loadTotais();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, campanhaCidade]);

  useEffect(() => {
    if (totalQuery.loading) return;

    setPassageiros(Number.safeParseInt(totalQuery.data?.corridasTotal) * 1.4);
  }, [totalQuery]);

  if (!campanha) {
    return (
      <Grid container sx={styles.container} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress sx={styles.loading} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={styles.container}
      alignContent={"space-between"}
      component={Paper}
    >
      <Grid container item xs={12} justifyContent={'space-between'}>
        <Typography sx={styles.titulo}>Passageiros</Typography>
        <Tooltip title="Estimativa de passageiros transportados" placement="bottom" arrow TransitionComponent={Zoom}>
          <InfoRounded sx={styles.infoIcon} />
        </Tooltip>
      </Grid>
      <Grid container item xs={12} alignItems="baseline" alignSelf="center">
        <Typography fontSize={12} mr={'auto'}>Total:</Typography>
        <Typography sx={styles.valor} title={Number.format(passageiros)}>{Number.format(passageiros)}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        alignSelf="flex-end"
        onClick={() => navigate(PASSAGEIROS_ROUTE)}
      >
        <Typography sx={styles.detalhes}>Ver detalhes <ChevronRight /></Typography>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    borderRadius: '6px',
    backgroundColor: theme => theme.palette.paterns.white,
    padding: '8px 16px',
    height: '100%',
    cursor: 'default',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  loading: {
    width: '20px !important',
    height: '20px !important',
  },
  titulo: {
    fontFamily: 'AktivGrotesk-Bold',
    marginBottom: '6px',
    overflow: 'hidden',
    height: '20px',
    fontSize: '14px',
  },
  infoIcon: {
    fill: '#0005',
    height: '14px',
    width: '14px',
    margin: '0px -5px -3px 5px'
  },
  detalhes: {
    fontFamily: 'AktivGrotesk-Light',
    color: theme => theme.palette.paterns.blueGray,
    fontSize: '12px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    'svg': {
      height: '12px',
      width: '12px',
      marginBottom: '-2px'
    },
    '&:hover': {
      color: theme => theme.palette.paterns.blueGrayHover,
    }
  },
  valor: {
    fontFamily: 'AktivGrotesk-Medium',
    color: theme => theme.palette.paterns.primary,
    fontSize: '24px',
    overflow: 'hidden',
  }
};

export default PassageirosCampanha;
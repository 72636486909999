import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import File from '../utils/file';
import {CameraAlt, CloudUpload} from '@mui/icons-material';
import ImagemModal from '../containers/modais/imagem-modal';

const UploadableFotoPerfil = ({
  label,
  image,
  setImage,
  handleChangeUploadImage,
  index = '',
  readOnly,
  sx
}) => {
  const isBase64 = image?.includes('base64');
  const [openImage, setOpenImage] = useState(false);
  console.log(image);

  if (readOnly) {
    return (
      <Box sx={sx}>
        {image && (
          <Box
            component={"img"}
            className='foto-container'
            sx={styles.fotoContainer}
            onClick={() => setOpenImage(true)}
            src={isBase64 ? image : File.getImageAuth(image)}
            alt={label}
            onError={() => setImage(null)}
          />
        )}
        {!image && (
          <Box className='foto-container' sx={styles.fotoContainer}>
            <CameraAlt sx={styles.placeholderIconDisabled} />
            <Typography sx={styles.placeholderLabelDisabled}>
              Nenhuma foto disponível
            </Typography>
          </Box>
        )}
        {openImage && image && (
          <ImagemModal
            visible={openImage}
            id={image}
            titulo={label}
            onClose={() => setOpenImage(false)}
          />
        )}
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <label
        sx={styles.uploadButtonContainer}
        htmlFor={`logo-input-file${index !== "" ? '-' + index : ''}`}
      >
        {image && (
          <Box
            component={"img"}
            sx={[styles.fotoContainer, {cursor: 'pointer'}]}
            src={isBase64 ? image : File.getImageAuth(image)}
            alt={label}
            onError={() => setImage(null)}
          />
        )}
        {!image && (
          <Box sx={[styles.fotoContainer, {cursor: 'pointer'}]}>
            <CloudUpload sx={styles.placeholderIcon} />
          </Box>
        )}
        <input
          id={`logo-input-file${index !== "" ? '-' + index : ''}`}
          type="file"
          style={styles.uploadInputFile}
          onChange={handleChangeUploadImage}
          disabled={readOnly}
        />
      </label>
    </Box>
  );
};

const styles = {
  label: {
    display: 'block',
    color: '#8995b1',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '2px',
  },
  uploadButtonContainer: {
    display: 'block',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  fotoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  uploadInputFile: {
    width: '1px',
    opacity: '0.0',
    position: 'absolute',
  },
  placeholderIcon: {
    color: '#8995b1',
    width: ' 40px',
    height: ' 40px',
  },
  placeholderLabel: {
    color: '#8995b1',
    fontSize: '13px',
    marginBottom: '0px',
    marginTop: '10px',
    cursor: 'default',
    textAlign: 'center'
  },
  placeholderIconDisabled: {
    color: '#CDCDCD',
    width: ' 40px',
    height: ' 40px',
  },
  placeholderLabelDisabled: {
    color: '#CDCDCD',
    fontSize: '13px',
    marginBottom: '0px',
    marginTop: '10px',
    cursor: 'default',
    textAlign: 'center'
  },
};

export default UploadableFotoPerfil;

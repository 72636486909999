import React, {useState, createRef, useEffect} from 'react';
import {CircularProgress, Grid, Typography} from '@mui/material';
import {Event} from '@mui/icons-material';
import {Bar} from 'react-chartjs-2';

require('../../components/charts/bar-chart.js');

const GraficoBarras = ({
  campanha,
  meses,
  periodos,
  periodoSelecionado,
  labelBarra,
  titulo,
  loading,
  containerMinHeight = '35vh',
  grafHeight = 130
}) => {
  const graficoRef = createRef();

  const [graficoConfig, setGraficoConfig] = useState(undefined);
  const [isDiario, setIsDiario] = useState(campanha?.quantidadeMeses === 1);

  useEffect(() => {
    if (!graficoRef.current || !periodos) return;

    const maxValue = Math.max(periodos?.reduce((a, b) => Math.max(a, b), 0) * 1.3, 200);
    const data = periodos?.map(a => parseInt(a || 0));
    const weekend = Array.from({length: meses.length}, () => -1);

    meses.forEach((value, index) => {
      if (!['sáb', 'dom'].includes(value[1])) return;

      weekend[index] = data[index];
      data[index] = -1;
    });

    const ctx = graficoRef.current.chartInstance.ctx;
    const y1offset = graficoRef.current.chartInstance.height;
    const gradientBar = ctx.createLinearGradient(0, 0, 0, y1offset);
    const gradientBarWeekend = ctx.createLinearGradient(0, 0, 0, y1offset);

    gradientBar.addColorStop(0, '#006EC9');
    gradientBar.addColorStop(.3, '#006EC9');
    gradientBar.addColorStop(1, '#00B3FF');

    gradientBarWeekend.addColorStop(0, '#00C96E');
    gradientBarWeekend.addColorStop(.3, '#00C96E');
    gradientBarWeekend.addColorStop(1, '#00FFB3');

    setGraficoConfig({
      datasets: [
        {
          label: 'weekend',
          data: weekend,
          backgroundColor: gradientBarWeekend,
          hideInLegendAndTooltip: true,
          parsing: {
            showTooltips: false
          }
        },
        {
          label: labelBarra,
          data,
          backgroundColor: gradientBar,
        },
        {
          label: 'backtrack',
          data: Array.from({length: meses.length}, () => maxValue),
          backgroundColor: '#F7F7F7',
          hideInLegendAndTooltip: true,
          parsing: {
            showTooltips: false
          }
        }
      ],
      maxValue: maxValue
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, periodos]);

  useEffect(() => {
    setIsDiario(campanha?.quantidadeMeses === 1 || periodoSelecionado);
  }, [campanha, meses, periodoSelecionado]);

  return (
    <Grid sx={styles.container} style={{minHeight: containerMinHeight}}>
      <Grid container item direction='row' alignItems='center'>
        <Event sx={styles.totaisIcon} />
        <Typography sx={styles.totaisTitle}>{titulo} por {isDiario ? 'dia' : 'período'}</Typography>
      </Grid>
      <Grid sx={styles.graficoContainer} className={`graph-bar`}>
        {loading && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress sx={styles.loading} />
          </Grid>
        )}
        {periodos && (
          <Bar
            label={titulo}
            ref={graficoRef}
            data={{
              labels: meses,
              ...graficoConfig
            }}
            height={grafHeight}
            width={800}
            options={{
              cornerRadius: 6,
              tooltips: {enabled: false},
              hover: {mode: null},
              legend: {display: false},
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawOnChartArea: false,
                      display: false
                    },
                    stacked: true
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      drawOnChartArea: false,
                      display: false
                    },
                    ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 6,
                      display: false,
                      min: 0,
                      max: graficoConfig?.maxValue || 2000
                    },
                    angleLines: {
                      display: false
                    },
                  }
                ]
              },
              plugins: {
                datalabels: {
                  color: '#0008',
                  padding: '0',
                  align: 'end',
                  anchor: 'end',
                  rotation: meses?.length > 6 ? 345 : 0,
                  font: {
                    weight: 'bold',
                    size: meses?.length > 4 ? 10 : 14
                  },
                  formatter: (value, {datasetIndex}) => {
                    if (2 === datasetIndex || value < 0)
                      return '';

                    return value?.toLocaleString();
                  }
                },
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '0px 2px 12px 0px #0000001A',
    marginBottom: '16px',
  },
  totaisIcon: {
    color: '#031663',
    height: '20px',
    width: '20px',
    margin: '0 7px',
  },
  totaisTitle: {
    color: '#031663',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  graficoContainer: {
    position: 'relative',
    padding: '20px 0 10px 0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff6',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  loading: {
    width: '30px !important',
    height: '30px !important',
  },
};

export default GraficoBarras;
import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Navigate,
  Route,
  HashRouter as Router,
  Routes
} from 'react-router-dom';
import {SidebarComponent} from '../components/sidebar';
import {ToolBarComponent} from '../components/toolbar';
import {
  CONTATO_ROUTE,
  HOME_ROUTE,
  IMAGENS_ROUTE,
  IMPACTOS_ROUTE,
  MAPA_DE_CALOR_ROUTE,
  PASSAGEIROS_ROUTE,
  PERFIL_ROUTE,
  QUILOMETRAGEM_ROUTE
} from '../constants/routes';
import {ANUNCIANTE_LOGADO} from '../graphql/queries';
import {persistUser} from '../redux/Auth/slice';
import ContatoView from '../views/contato';
import HomeView from '../views/home';
import CheckInCheckOutView from '../views/imagens-campanha';
import ImpactosView from '../views/impactos';
import MapaCalorView from '../views/mapa-de-calor';
import PassageirosView from '../views/passageiros';
import PerfilView from '../views/perfil';
import QuilometrosView from '../views/quilometros';
import ScrollTop from './scroll-top';

export const RouterComponent = () => {
  const dispatch = useDispatch();

  const {sidebarOpen} = useSelector(state => state.Sidebar);

  const anuncianteLogadoQuery = useQuery(ANUNCIANTE_LOGADO);

  useEffect(() => {
    const anuncianteLogado = anuncianteLogadoQuery?.data?.anuncianteLogado;
    if (anuncianteLogado) {
      dispatch(persistUser(anuncianteLogado));
    }
  }, [anuncianteLogadoQuery, dispatch]);

  return (
    <Router>
      <ScrollTop />
      <FullWrapper>
        <SidebarComponent />
        <Content open={sidebarOpen}>
          <ToolBarComponent />
          <Main>
            <Routes>
              <Route path={HOME_ROUTE} element={<HomeView />} />
              <Route path={QUILOMETRAGEM_ROUTE} element={<QuilometrosView />} />
              <Route path={IMPACTOS_ROUTE} element={<ImpactosView />} />
              <Route path={PASSAGEIROS_ROUTE} element={<PassageirosView />} />
              <Route path={IMAGENS_ROUTE} element={<CheckInCheckOutView />} />
              <Route path={PERFIL_ROUTE} element={<PerfilView />} />
              <Route path={CONTATO_ROUTE} element={<ContatoView />} />
              <Route path={MAPA_DE_CALOR_ROUTE} element={<MapaCalorView />} />
              <Route path="*" element={<Navigate to={HOME_ROUTE} />} />
            </Routes>
          </Main>
        </Content>
      </FullWrapper>
    </Router>
  );
};

const Content = styled.div`
  display: inline-block;
  width: 100%;
  transition: padding-left .2s ease-in-out;
  padding-left: ${({theme, open}) => open ? theme.sidebarWidthOpen : theme.sidebarWidth}
`;

const FullWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.palette.paterns.background};
`;

const Main = styled.main`
  width: 100%;
  padding: 0;
  height: ${props => `calc(100vh - ${props.theme.toolbarHeight})`};
  overflow-y: auto;
`;

import Axios from 'axios';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  Observable
} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import config from './config/config';

const httpLink = new createHttpLink({
  uri: config.API_BASE_URL
});

const cache = new InMemoryCache();

const refreshTokenApi = ({refresh_token}) => {
  let address = `${config.OAUTH_BASE_URL}?grant_type=refresh_token&refresh_token=${refresh_token}`;
  const headersRefresh = {
    authorization: `basic ${window.btoa(
      `${config.AUTH_USERNAME}:${config.AUTH_PASSWORD}`
    )}`
  };

  return Axios.post(
    address,
    {},
    {
      headers: headersRefresh
    }
  );
};

const initApolloClient = ({refresh_token, onSucessRefresh}) => {
  const authLink = setContext((_, {headers}) => {
    // return the headers to the context so httpLink can read them
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const authorization =
      auth && auth.access_token ? `Bearer ${auth.access_token}` : '';

    return {
      headers: {
        ...headers,
        authorization
      }
    };
  });

  let callRefreshToken = false;
  let operationPendentes = [];

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      authLink,
      onError(({networkError, operation, forward}) => {
        if (networkError && networkError.result) {
          // User access token has expired
          if (networkError.result.error === 'invalid_grant') {
            window.localStorage.clear();
            return;
          }

          if (networkError.statusCode === 401) {
            // We assume we have both tokens needed to run the async request
            if (refresh_token) {
              // Let's refresh token through async request
              return new Observable(observer => {
                if (callRefreshToken) {
                  operationPendentes.push({observer, operation, forward});
                  return;
                }

                callRefreshToken = true;
                refreshTokenApi({refresh_token})
                  .then(refreshResponse => {
                    callRefreshToken = false;
                    const access_token = refreshResponse.data.access_token;
                    onSucessRefresh && onSucessRefresh(refreshResponse.data);
                    operation.setContext(({headers = {}}) => ({
                      headers: {
                        // Re-add old headers
                        ...headers,
                        // Switch out old access token for new one
                        authorization: `Bearer ${access_token}`
                      }
                    }));

                    operationPendentes.forEach(item => {
                      item.operation.setContext(({headers = {}}) => ({
                        headers: {
                          // Re-add old headers
                          ...headers,
                          // Switch out old access token for new one
                          authorization: `Bearer ${access_token}`
                        }
                      }));
                    });
                  })
                  .then(() => {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer)
                    };

                    // Retry last failed request
                    forward(operation).subscribe(subscriber);

                    operationPendentes.forEach(item => {
                      const subscriber = {
                        next: item.observer.next.bind(item.observer),
                        error: item.observer.error.bind(item.observer),
                        complete: item.observer.complete.bind(item.observer)
                      };
                      item.forward(item.operation).subscribe(subscriber);
                    });

                    operationPendentes = [];
                  })
                  .catch(error => {
                    // No refresh or client token available, we force user to login
                    callRefreshToken = false;
                    operationPendentes = [];
                    window.localStorage.clear();
                    observer.error(error);
                  });
              });
            }
          }
        }
      }),
      httpLink
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });
};

export default initApolloClient;

import currency from 'currency.js';

const Number = {
  format: (value, locales = 'pt-BR', options = {}) => {
    let currentValue = value;
    if (!parseFloat(value, 10)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat(locales, {
      maximumFractionDigits: 2,
      ...options
    }).format(currentValue);
  },
  currencyFormat: (value, locales = 'pt-BR', options = {currency: 'BRL'}) => {
    let currentValue = value;
    if (!parseFloat(value)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat(locales, {
      ...options,
      style: 'currency'
    }).format(currentValue);
  },
  floatFormat: (value, locales = 'pt-BR', options = {}) => {
    let currentValue = value;
    if (!parseFloat(value, 10)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat(locales, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      ...options
    }).format(currentValue);
  },
  currencyFormatDigitos: (value, casas = 2) => {
    let currentValue = value;
    if (!parseFloat(value)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: casas,
      style: 'currency'
    }).format(currentValue);
  },
  stringToFloat: value => {
    if (!value)
      return 0

    return currency(value, {separator: '.', decimal: ','}).value;
  },
  safeParseInt: value => {
    if (!value || isNaN(value)) return 0;

    return parseInt(value);
  },
  safeParseFloat: value => {
    if (!value || isNaN(value)) return 0;

    return parseFloat(value);
  },
  getPorcentagem: (valor, total) => {
    if (!total || !valor || total === 0) return 0;
    
    return (Number.stringToFloat(valor) * 100) / Number.stringToFloat(total);
  }
};

export default Number;

import {createSlice} from '@reduxjs/toolkit';

const getSidebarOpen = () => {
  const open = window.sessionStorage.getItem("isSidebarOpen");
  if (open === null) return true;

  return open === 'true';
}

const initialState = {
  sidebar: [],
  sidebarOpen: getSidebarOpen()
};

const acaoSetOpenSidebar = (state, action) => {
  state.sidebarOpen = action.payload;
  window.sessionStorage.setItem('isSidebarOpen', action.payload); 
};

const resumeSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    setOpenSidebar: acaoSetOpenSidebar,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setOpenSidebar} = actions;

import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import {Grid, Paper, Typography} from '@mui/material';
import {PrintRounded} from '@mui/icons-material';
import Mapa from './mapa';
import {RoundTooltipButton} from '../../components/mui-button';
import Data from '../../utils/data';
import PeriodosHeadDashboard from '../../components/periodos';

const backgroundMap = require('../../assets/images/mapa.jpg');

const pageStyle = `
  @page { 
    size: A4; 
    margin: 0mm 5mm 0mm 5mm;
  }

  @media print {
    .chartjs-size-monitor {
      position: fixed !important;
    }
    html, body, #root {
      width: 297mm;
      height: 420mm;
      background: #fff !important;
    }
  }

  @media all {
    .impressao-mapa-container {
      margin-top: 0px !important;
      padding-top: 200px !important;
    }
    .impressao-container {
      height: 1400px !important;
    }
    .map-container {
      height: 100% !important;
    }
    .report-header {
      display: block !important
    }
    .report-footer {
      display: block !important;
    }
    div {
      -webkit-print-color-adjust: exact;
    }
    .MuiPaper-elevation {
      box-shadow: none !important;
    }
  }
`;

const MapaCalor = () => {
  const {campanhaSelected, cidadeSelected} = useSelector(
    (store) => store.Toolbar,
  );
  const {periodoSelecionado, isPorPeriodos} = useSelector(
    (store) => store.Periodos,
  );

  const componentRef = useRef();

  const {periodos} = Data.getPeriodosCampanha(
    campanhaSelected,
    isPorPeriodos,
    periodoSelecionado,
  );

  return (
    <Grid id="page-container">
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Mapa de calor - ${campanhaSelected?.anunciante?.nomeFantasia}`}
        trigger={() => (
          <RoundTooltipButton
            key="print-button"
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintRounded />}
            loadingColor="grey"
            style={{visibility: cidadeSelected ? 'visible' : 'hidden'}}
          />
        )}
        content={() => componentRef.current}
      />
      <PeriodosHeadDashboard />
      <Paper
        ref={componentRef}
        sx={styles.mapaContainer}
        className="impressao-container">
        {cidadeSelected && (
          <Mapa
            key="mapa-key"
            campanha={campanhaSelected}
            campanhaCidade={cidadeSelected}
            periodo={periodos[periodoSelecionado]}
            periodos={periodos}
          />
        )}
        {!cidadeSelected && (
          <Grid container item xs={12} sx={styles.imageBackground}>
            <Grid container sx={styles.placeholderContainer}>
              <Typography sx={styles.placeholderText}>
                Selecione uma cidade para gerar o mapa
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

const styles = {
  mapaContainer: {
    overflow: 'hidden',
    height: 'calc(100vh - 76px)',
  },
  imageBackground: {
    height: '100%',
    borderRadius: '6px',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundMap})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  placeholderContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#fffc',
    justifyContent: 'center',
  },
  placeholderText: {
    opacity: 1,
    fontFamily: 'AktivGrotesk-Medium',
    fontSize: '20px',
    marginTop: '80px',
    lineHeight: '22px',
    textAlign: 'center',
    color: (theme) => theme.palette.paterns.navyBlue,
    padding: '8px',
    height: '40px',
    borderRadius: '4px',
    background: '#fffb',
  },
};

export default MapaCalor;

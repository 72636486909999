import React from 'react';
import {Grid, TableCell} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';

const TableCellOrdered = ({column, orderBy, setOrderBy, ...props}) => {
  const handleOrderByArrow = (column) => {
    if (column !== orderBy.campo)
      return '';

    return orderBy.asc ? <ArrowDropUp sx={styles.orderByIcon} /> : <ArrowDropDown sx={styles.orderByIcon} />
  }

  const handleClickColumn = (column) => {
    if (column === orderBy.campo) {
      setOrderBy({...orderBy, asc: !orderBy.asc})
      return;
    }

    setOrderBy({campo: column, asc: true})
  };

  return (
    <TableCell
      key={column.id}
      align={column.align}
      sx={column.calculado ? styles.columnHeadCalculado : styles.columnHead}
      onClick={() => !column.calculado && handleClickColumn(column.campo || column.id)}
      {...props}
    >
      <Grid sx={styles.iconLabelGroup} style={{justifyContent: column.align || 'left'}}>
        {handleOrderByArrow(column.campo || column.id)} {column.label}
      </Grid>
    </TableCell>
  );
};

const styles = {
  orderByIcon: {
    verticalAlign: 'top'
  },
  iconLabelGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  columnHead: {
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  columnHeadCalculado: {
    cursor: 'default',
    fontWeight: 'bold'
  }
};

export default TableCellOrdered;
import {useEffect} from 'react';

const inactiveIcon = require('../assets/icons/carro-inactive-point-icon.png');
const activeIcon = require('../assets/icons/carro-active-point-icon.png');

const eventMapping = {
  onClick: 'click',
  onDoubleClick: 'dblclick',
  onMouseOver: 'mouseover',
  onMouseOut: 'mouseout',
};

export default function useGoogleMapMarker({
  position,
  active = false,
  maps,
  map,
  events,
  title,
  label,
  icon,
}) {
  useEffect(() => {
    const marker = new maps.Marker({
      position,
      map,
      title,
    });
    marker.setIcon(icon || (active ? activeIcon : inactiveIcon));
    label && marker.setLabel(label)
    Object.keys(events).forEach(eventName =>
      marker.addListener(eventMapping[eventName], () => {
        events[eventName](map, marker);
      }),
    );

    return function cleanup() {
      marker.setMap(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, icon]);

  return null;
}

import {combineReducers} from 'redux';
import {AuthReducer as Auth} from './Auth';
import {ReducerApollo as Apollo} from './Apollo';
import {ReducerSidebar as Sidebar} from './Sidebar';
import {ReducerToolbar as Toolbar} from './Toolbar';
import {ReducerPeriodos as Periodos} from './Periodos';

const appReducer = combineReducers({
  Auth,
  Apollo,
  Sidebar,
  Toolbar,
  Periodos
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;

import React from 'react';
import {Grid, Typography} from '@mui/material';
import {Dashboard} from '@mui/icons-material';

const GraficoTotais = ({titulo, totais}) => {
  return (
    <Grid container direction='column'>
      <Typography sx={styles.subtitle}>{titulo}</Typography>
      <Grid sx={styles.container}>
        <Grid container item direction='row' alignItems='center'>
          <Dashboard sx={styles.totaisIcon} />
          <Typography sx={styles.totaisTitle}>Resumo geral</Typography>
        </Grid>
        <Grid container item direction='row'>
          {totais.map(({label, value}, index) => (
            <Grid key={index} item sx={styles.infoContainer}>
              <Typography sx={styles.infoHeader}>{label}</Typography>
              <Typography sx={styles.infoValue}>{value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  subtitle: {
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    color: '#0A2549',
    marginBottom: '16px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '0px 2px 12px 0px #0000001A',
    marginBottom: '16px',
  },
  totaisIcon: {
    color: '#031663',
    height: '20px',
    width: '20px',
    margin: '0 7px',
  },
  totaisTitle: {
    color: '#031663',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  infoContainer: {
    backgroundColor: '#F7F7F7',
    borderRadius: '4px',
    height: '71px',
    minWidth: '234px',
    padding: '8px',
    margin: '12px 16px 0px 0px',
    textAlign: 'center',
    boxShadow: '0px 1px 2px 0px #00000029',
  },
  infoHeader: {
    minWidth: '100%',
    height: '23px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#007BE1',
    fontFamily: 'Poppins',
    borderRadius: '4px',
    padding: '2px 8px',
    marginBottom: '8px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
  infoValue: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0A2549',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
}

export default GraficoTotais;
import React, {useRef} from 'react';
import styled from '@emotion/styled';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import {Cancel} from '@mui/icons-material';
import InputBase from './mui-input-base';

const InputStyled = styled(InputBase)`
  height: 40px;
  border-color: rgba(0, 0, 0 0);
`;

const InputV2 = ({
  label,
  mensagemSuporte,
  className,
  classInput = 'input',
  value = '',
  ...props
}) => {
  const inputRef = useRef(null);
  return (
    <FormControl fullWidth size="small" className="input-v2">
      <InputLabel
        htmlFor="inputV2"
        size="small"
        id="input-label-v2"
        shrink={value === 0 || value ? true : false}
        className={`${props.error ? 'error' : ''}`}
        disabled={props.disabled}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="input-v2"
        inputComponent={Input}
        label={label ? `${label}..` : ''}
        disabled={props.disabled}
        className={`${value === 0 || value ? 'withValue' : ''}${props.error ? '-error' : ''}`}
        inputProps={{
          renderRight: props.error
            ? () => <Cancel titleAccess={mensagemSuporte} id="icon-error" />
            : () => null,
          value: value,
          ...props,
          ref: {inputRef},
        }}
        size="small"
        notched={value === 0 || value ? true : false}
        placeholder={props.placeholder}
        endAdornment={props.endAdornment}
        startAdornment={props.startAdornment}
      />
      {props.error && (
        <FormHelperText
          sx={{color: (theme) => theme.palette.cancel.main}}
          id="support-message-id"
        >
          {mensagemSuporte}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const Input = React.forwardRef((props, ref) => (
  <InputStyled innerRef={ref} {...props} />
));

export default InputV2;

import React from 'react';
import {CircularProgress, Grid, Typography} from '@mui/material';
import {DateRange} from '@mui/icons-material';
import Number from '../../utils/number';

const GraficoDiaSemana = ({valorTotal, dias, titulo, unidadeMedida = '', loading}) => {
  return (
    <Grid sx={styles.container} style={{width: 'calc(50% - 8px)', marginRight: '8px', justifyContent: 'space-between'}}>
      <Grid container item direction='row' alignItems='center'>
        <DateRange sx={styles.totaisIcon} />
        <Typography sx={styles.totaisTitle}>{titulo} por dia da semana</Typography>
      </Grid>
      <Grid container direction='column' sx={{position: 'relative', }}>
        {loading && (
          <Grid sx={styles.loadingContainer}>
            <CircularProgress sx={styles.loading} />
          </Grid>
        )}
        <DiaItem diaLabel={'Dom'} diaValor={'SUNDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Seg'} diaValor={'MONDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Ter'} diaValor={'TUESDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Qua'} diaValor={'WEDNESDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Qui'} diaValor={'THURSDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Sex'} diaValor={'FRIDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
        <DiaItem diaLabel={'Sáb'} diaValor={'SATURDAY'} dias={dias} valorTotal={valorTotal} unidadeMedida={unidadeMedida} />
      </Grid>
    </Grid>
  );
}

const DiaItem = ({diaLabel, diaValor, dias = [], valorTotal, unidadeMedida}) => {
  const getValorDia = (dia) => {
    if (!dias?.length) return '0';

    const valor = Number.safeParseInt(dias.filter(d => dia === d.chave.trim())[0]?.valor);
    return Number.format(valor);
  }

  const getValorDiaPercent = (dia) => {
    if (!dias?.length) return '0';

    const valor = Number.safeParseFloat(dias.filter(d => dia === d.chave.trim())[0]?.valor);
    const percent = Number.safeParseInt(Number.getPorcentagem(valor, valorTotal * .7));
    return `calc(${percent}% + 100px)`;
  }

  const isDiaMax = diaValor === dias[0]?.chave?.trim();

  return (
    <Grid container direction='row'>
      <Typography sx={styles.diaSemanaText}>
        {diaLabel}
      </Typography>
      <Grid container direction='row' sx={styles.diaSemanaContainer}>
        <Typography
          sx={[styles.diaSemanaValue, isDiaMax && styles.diaSemanaTextMax]}
          style={{width: getValorDiaPercent(diaValor)}}
        >
          {`${getValorDia(diaValor)} ${unidadeMedida}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '4px',
    background: '#fff',
    boxShadow: '0px 2px 12px 0px #0000001A',
    marginBottom: '16px',
  },
  totaisIcon: {
    color: '#031663',
    height: 20,
    width: 20,
    margin: '0 7px',
  },
  totaisTitle: {
    color: '#031663',
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  diaSemanaContainer: {
    height: '42px',
    marginTop: '8px',
    background: '#F7F7F7',
    borderRadius: 30,
    width: 'calc(100% - 45px)',
  },
  diaSemanaText: {
    width: '45px',
    alignSelf: 'center',
    marginTop: '8px',
  },
  diaSemanaValue: {
    color: '#262626',
    backgroundColor: '#0091FF29',
    fontSize: '20px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    padding: '6px 16px',
    borderRadius: '214px',
    minWidth: '90px',
    whiteSpace: 'nowrap',
  },
  diaSemanaTextMax: {
    color: '#fff',
    backgroundColor: '#0091FF',
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff6',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  loading: {
    width: '30px !important',
    height: '30px !important',
  },
}

export default GraficoDiaSemana;
import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import moment from 'moment';
import {Box, Grid, Typography} from '@mui/material';
import {centroBrasil} from '../../utils/get-places';
import Map from '../../google/Map';
import Heatmap from '../../google/Heatmap';
import {GERAR_MAPA_DE_CALOR} from '../../graphql/queries';
import {getImageAuth} from '../../utils/get-image';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Data from '../../utils/data';

const Divisor = require('../../assets/images/divisor-relatorio.png');
const AvatarImg = require('../../assets/images/avatar-default.png');

const Mapa = ({campanha, campanhaCidade, periodo, periodos}) => {
  const zoom = 13;

  const [avatar, setAvatar] = useState(undefined);
  const [buffer, setBuffer] = useState([]);
  const [pontos, setPontos] = useState([]);

  const [loadMapa, {data, loading}] = useLazyQuery(GERAR_MAPA_DE_CALOR);

  useEffect(() => {
    setAvatar(getImageAuth(campanha?.bannerCampanha));
  }, [campanha]);

  useEffect(() => {
    setBuffer([]);
    setPontos([]);

    buscarPontos(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaCidade, periodo]);

  useEffect(() => {
    if (!data?.gerarMapaDeCalor) return;
    const pagina = data.gerarMapaDeCalor.pageable?.pageNumber || 0;

    if (pagina === 0) setBuffer(data.gerarMapaDeCalor.content);
    else setBuffer([...buffer, ...data.gerarMapaDeCalor.content]);

    if (!data.gerarMapaDeCalor.last) return buscarPontos(pagina + 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (loading || !buffer) return;

    setPontos(buffer);
  }, [buffer, loading]);

  const buscarPontos = (pageNumber) => {
    if (!campanhaCidade) return;
    loadMapa({
      variables: {
        dataInicio: periodo?.inicio?.format('YYYY-MM-DD'),
        dataFim: periodo?.fim?.format('YYYY-MM-DD'),
        campanhaCidade: {
          id: campanhaCidade?.id,
        },
        pageable: {
          pageNumber,
          pageSize: 20000,
        },
      },
    });
  };

  const stringPeriodoReferencia = () => {
    let dataInicio = moment(campanha?.dataInicioVeiculacao);
    let dataFim = Data.dataAtualOuFinalCampanha(
      campanha?.dataInicioVeiculacao,
      campanha?.diasPeriodo,
      campanha?.quantidadeMeses,
    );

    if (periodo) {
      dataInicio = moment(periodo.inicio);
      dataFim = moment(periodo.fim);
    }

    return `${dataInicio.format('DD/MM/YY')} até ${dataFim.format('DD/MM/YY')}`;
  };

  return (
    <Grid className="map" sx={styles.container}>
      <Grid
        item
        xs={12}
        sx={styles.reportContainer}
        className="report-header"
        style={{display: 'none'}}>
        <Grid sx={styles.reportHeader}>
          <Grid item xs={12} style={{padding: '0 10px'}}>
            <img
              alt={'divisor'}
              style={{width: '100%', margin: '10px 0'}}
              src={Divisor}
            />
          </Grid>
          <Grid item xs={12} sx={styles.reportHeaderContent}>
            <Grid
              item
              xs={2}
              style={{display: 'flex', justifyContent: 'center'}}>
              <Box
                component="img"
                src={avatar}
                alt={campanha?.titulo || ''}
                onError={() => setAvatar(AvatarImg)}
                sx={styles.avatar}
              />
            </Grid>
            <Grid item xs={8} style={{height: '100px', width: '100%', paddingLeft: '32px'}}>
              <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
                Titulo: {campanha?.titulo || ''}
              </Typography>
              <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
                Cliente: {campanha?.anunciante?.nomeRazao || ''}
              </Typography>
              <Typography
                sx={styles.titleReport}
                style={{fontSize: 14, color: '#657496'}}>
                Cidades: {campanhaCidade?.cidade?.nomeEUf || ' - '}
              </Typography>
              <Typography
                sx={styles.titleReport}
                style={{fontSize: 14, color: '#657496'}}>
                Período de Referência: {stringPeriodoReferencia()}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <KmMidiaLogo style={{width: '100%'}} />
            </Grid>
          </Grid>
          {!!campanha.observacoesRelatorios && (
            <Grid item xs={12} sx={styles.observacaoContainer}>
              <pre>{`Observações: ${campanha.observacoesRelatorios}`}</pre>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{marginTop: 125, padding: '2px 2px 2px 20px'}}>
          <Grid item xs={12} style={{display: 'flex', flexDirection: 'row'}}>
            {!periodo && (
              <>
                <Grid item>
                  <Typography sx={styles.periodosTitle}>Períodos:</Typography>
                </Grid>
                <Grid container item style={{display: 'flex'}}>
                  {periodos.map((periodo, index) => (
                    <Typography key={index} sx={styles.periodos}>
                      {`P${index + 1}: ${periodo.inicio.format(
                        'DD/MM/YYYY',
                      )} - ${periodo.fim.format('DD/MM/YYYY')}`}
                    </Typography>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={styles.mapContainer}
        className="impressao-mapa-container"
        style={{height: '100%', position: 'relative'}}>
        {loading && (
          <Grid sx={styles.loadingContainer}>
            <Typography sx={styles.subtitle}>Carregando...</Typography>
          </Grid>
        )}
        <Map
          zoom={zoom}
          mapTypeId="satellite"
          center={{
            lat: campanhaCidade?.cidade?.latitude || centroBrasil.lat,
            lng: campanhaCidade?.cidade?.longitude || centroBrasil.lng,
          }}
          options={{
            clickableIcons: false,
            disableDefaultUI: true,
            scrollwheel: true,
            zoomControl: true,
            fullscreenControl: true,
            mapTypeControl: true,
          }}
          locations={pontos}
          ajusteZoom={2}>
          <Heatmap
            points={pontos}
            toggleGradient={false}
            toggleOpacity={false}
            toggleRadius={true}
            toggleHeatmap={false}
          />
        </Map>
      </Grid>
      <Grid
        sx={styles.footer}
        className="report-footer"
        style={{display: 'none'}}>
        <Grid item xs={12} sx={styles.footerLogoContainer}>
          <KmMidiaLogo style={styles.footerLogo} />
          <Typography sx={styles.footerText}>
            Valorizando sua marca a cada KM rodado!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{padding: '0px'}}>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '5px 0'}}
            src={Divisor}
          />
        </Grid>
        <Typography style={{fontSize: 12}}>
          Relatório foi gerado no dia{' '}
          {moment().format('DD [de] MMMM [de] YYYY [às] HH[h]mm')}, válido por
          30 dias
        </Typography>
        <Typography style={{fontSize: 12}}>{`© Copyright ${moment().format(
          'YYYY',
        )}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}</Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#fff',
    overflow: 'hidden',

    '& .map-container': {
      height: '60vw',
      boxShadow: '0px 2px 12px 0px #0000001A',
    },
  },
  subtitle: {
    fontSize: 20,
    fontWeight: '500',
    fontFamily: 'Poppins',
    color: '#0A2549',
    marginBottom: '12px',
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff6',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  observacaoContainer: {
    breakInside: 'avoid',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'start',
    margin: '5px 0',
    paddingLeft: '50px',
    fontSize: 12,
  },
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 5px',
    width: '297mm',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    padding: '0px 10px',
    position: 'fixed',
    width: 'inherit',
    top: '5px',
    left: 0,
    backgroundColor: '#fff',
  },
  reportHeaderContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '20px',
    paddingRight: '20px',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '297mm',
    top: '1550px',
  },
  footerText: {
    margin: '20px 0 0 0 !important',
    fontSize: '16px !important',
    color: '#000',
    textAlign: 'center',
    fontWeight: '700 !important',
  },
  footerLogo: {
    height: '25px',
    width: '150px',
    margin: '0 20px -12px -5px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  periodosTitle: {
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    margin: '2px',
    marginLeft: '0px',
  },
  periodos: {
    whiteSpace: 'nowrap',
    margin: '2px 5px',
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    background: '#e8e8e8',
    borderRadius: '20px',
    padding: '0 10px',
  },
  avatar: {
    background: '#d2d7e2',
    objectFit: 'contain',
    backgroundColor: '#fff',
    width: '80px',
    height: '80px',
    display: 'block',
    borderRadius: '50%',
    marginTop: '16px',
    marginBottom: '10px',
  },
};

export default Mapa;

import React from 'react';
import Viewer from 'react-viewer';
import {getImageAuth} from '../../utils/get-image';

const ImagemModal = ({id, titulo, ...props}) => {
  return (
    <Viewer
      {...props}
      images={[{src: getImageAuth(id), alt: `${titulo} `}]}
      noNavbar
      changeable={false}
      showTotal={false}
    />
  );
};

export default ImagemModal;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import Data from '../utils/data';
import {setIsPorPeriodo, setPeriodosSelecionado} from '../redux/Periodos/slice';

const PeriodosHeadDashboard = () => {
  const dispatch = useDispatch();
  const {campanhaSelected: campanha} = useSelector((store) => store.Toolbar);
  const {periodoSelecionado, isPorPeriodos} = useSelector((store) => store.Periodos);

  const {periodos} = Data.getPeriodosCampanha(campanha, isPorPeriodos, periodoSelecionado);

  useEffect(() => {
    handleSetSelecionado(
      !isPorPeriodos
        ? Data.periodoDaCampanha(campanha?.dataInicioVeiculacao, campanha?.diasPeriodo, campanha?.quantidadeMeses)
        : Data.periodoDaCampanha(campanha?.dataInicioVeiculacao, campanha?.diasPeriodo, campanha?.quantidadeMeses) - 1,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, isPorPeriodos]);

  const handleSetIsPorPeriodos = (isPorPeriodos) => {
    dispatch(setIsPorPeriodo(isPorPeriodos));
  }

  const handleSetSelecionado = (periodo) => {
    dispatch(setPeriodosSelecionado(periodo));
  }

  return (
    <Paper id="filtro-paper" sx={{width: '100%'}}>
      <Grid container>
        <Grid container item xs={12} direction='row' justify='space-between' alignItems='baseline'>
          <Grid container item direction='row' justifyContent='flex-end' alignItems='center'>
            <Typography sx={styles.periodosTitle}>Ver relatórios por:</Typography>
            <Grid sx={styles.switchContainer}>
              <ToggleButtonGroup
                value={isPorPeriodos}
                exclusive
                onChange={() => {
                  handleSetIsPorPeriodos(!isPorPeriodos);
                  handleSetSelecionado(null);
                }}
                sx={styles.switchGroup}
                aria-label="tipo periodo"
              >
                <ToggleButton sx={styles.switchOption} value={true} aria-label="periodo">
                  <Typography sx={styles.switchLabel}>Período</Typography>
                </ToggleButton>
                <ToggleButton sx={styles.switchOption} value={false} aria-label="mensal">
                  <Typography sx={styles.switchLabel}>Mensal</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <Typography sx={styles.periodosTitle}>Filtrar por:</Typography>
          </Grid>
        </Grid>
        <Typography
          key={'todosPeriodos'}
          sx={[styles.periodos, periodoSelecionado === undefined && styles.periodosSelecionado]}
          onClick={() => handleSetSelecionado(undefined)}
          style={periodoSelecionado === undefined ? {color: '#fff', background: '#657496'} : null}
        >
          Todos
        </Typography>
        {campanha && periodos.map((periodo, index) => (
          <Typography
            key={index}
            sx={[styles.periodos, periodoSelecionado === index && styles.periodosSelecionado]}
            onClick={() => handleSetSelecionado(index)}
          >
            {`P${index + 1}: ${periodo.inicio.format('DD/MM/YYYY')} - ${periodo.fim.format('DD/MM/YYYY')}`}
          </Typography>
        ))}
      </Grid>
    </Paper>
  );
};

const styles = {
  periodosTitle: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    color: '#0A2549',
    padding: '2px 0px',
    maxHeight: '24px',
    marginRight: '10px',
    marginLeft: '5px'
  },
  switchContainer: {
    width: 200,
    margin: '8px 0 5px 0',
    height: 26,
    fontWeight: '500',
    fontFamily: 'Poppins'
  },
  switchGroup: {
    display: 'flex',
    padding: '2px',
    borderRadius: '20px',
    border: 0,
    background: '#f2f2f2',
    width: '100%',
    height: '100%',
    position: 'relative',
    opacity: 1
  },
  switchOption: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '50%',
    borderRadius: '18px !important',
    border: 'none',
    padding: '0px',
    '&.Mui-selected': {
      backgroundColor: '#fff !important'
    },
  },
  switchLabel: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '500 !important'
  },
  periodos: {
    margin: '3px 5px',
    fontSize: 14,
    color: '#000',
    background: '#fff',
    borderRadius: '6px',
    padding: '0px 10px',
    maxHeight: 24,
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontWeight: '500',
    minWidth: 120,
    textAlign: 'center',
    border: '2px solid rgba(137, 149, 176, 0.4)',
    boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 16%)'
  },
  periodosSelecionado: {
    color: '#E8EBF2',
    background: '#657496',
    border: '2px solid #657496',
  },
};

export default PeriodosHeadDashboard;
import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import Creatable from "react-select/creatable";
import {AsyncPaginate, withAsyncPaginate} from 'react-select-async-paginate';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const noOptionsMessage = ({inputValue}) => {
  if (typeof inputValue === 'string' && inputValue.length) {
    return 'Nenhum item encontrado';
  }
  return 'Digite para pesquisar';
};

const SelectBase = ({paginate, loadOptions, creatable, onCreateOption, ...props}) => {

  if (paginate && typeof loadOptions === 'function') {
    if (creatable) {
      return (
        <CreatableAsyncPaginateStyle
          classNamePrefix="react-select"
          loadingMessage={() => 'Carregando...'}
          noOptionsMessage={noOptionsMessage}
          loadOptions={loadOptions}
          onCreateOption={onCreateOption}
          formatCreateLabel={(value) => `Criar "${value}"`}
          {...props}
        />
      );
    }
    return (
      <AsyncPaginateStyled
        classNamePrefix="react-select"
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={noOptionsMessage}
        loadOptions={loadOptions}
        {...props}
      />
    );
  }

  return (
    <SelectStyled
      classNamePrefix="react-select"
      noOptionsMessage={noOptionsMessage}
      {...props}
    />
  );
};

const SelectStyled = styled(Select)`
  .react-select__control {
    border-radius: 4px;
    border: 0px;
    box-shadow: none;
    height: 40px;
    position: absolute;
    top: -0px;
    right: 0px;
    left: 0px;
    width: 100%;

    &:hover {
      border-color: #8995b1;
    }

    ${(props) => {
      if (props.error) {
        return `
          border: 1px solid #ff5252 !important;
        `;
      }
    }}
  }
  .react-select__control--is-focused {
    border-color: #8995b1;
  }
  .react-select__control--menu-is-open {
    border-color: #8995b1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .react-select__indicator {
      transform: rotate(180deg);
    }
  }
  .react-select__indicators {
    & > span {
      display: none;
    }
  }
  .react-select__single-value {
    color: #657496 !important;
  }
  .react-select__value-container {
    font-size: 14px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: #afb7c9;
  }
  .react-select__menu-notice {
    font-size: 14px;
    color: #8995b1;
  }
  .react-select__indicator {
    color: #afb7c9;
    transition: transform 0.3s ease-in-out;

    &:hover {
      color: #8995b1;
    }
  }

  .react-select__menu {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: none;
    border: 1px solid #8995b1;
    border-top: 0;
    position: absolute;
    left: 0px;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    font-size: 14px;
    color: #8995b1;

    &:active {
      background-color: rgba(207, 212, 223, 0.2);
      font-weight: bold;
    }
  }

  .react-select__option--is-selected {
    background-color: rgba(207, 212, 223, 0.2);
    font-weight: bold;
  }

  .react-select__option--is-focused {
    background-color: rgba(207, 212, 223, 0.2);
  }
`;

const AsyncPaginateStyled = styled(AsyncPaginate)`
  .react-select__control {
    border-radius: 4px;
    border: 0;
    box-shadow: none;
    height: 40px;
    position: absolute;
    top: -0px;
    right: 0px;
    left: 0px;
    width: 100%;

    &:hover {
      border-color: #8995b1;
    }

    ${(props) => {
      if (props.error) {
        return `
          border: 1px solid #ff5252 !important;
        `;
      }
    }}
  }
  .react-select__control--is-focused {
    border-color: #8995b1;
  }
  .react-select__control--menu-is-open {
    border-color: #8995b1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .react-select__indicator {
      transform: rotate(180deg);
    }
  }
  .react-select__indicators {
    & > span {
      display: none;
    }
  }
  .react-select__single-value {
    color: #657496 !important;
  }
  .react-select__value-container {
    font-size: 14px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: #afb7c9;
  }
  .react-select__menu-notice {
    font-size: 14px;
    color: #8995b1;
  }
  .react-select__indicator {
    color: #afb7c9;
    transition: transform 0.3s ease-in-out;

    &:hover {
      color: #8995b1;
    }
  }

  .react-select__menu {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: none;
    border: 1px solid #8995b1;
    border-top: 0;
    position: absolute;
    left: 0px;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    font-size: 14px;
    color: #8995b1;
    &:active {
      background-color: rgba(207, 212, 223, 0.2);
      font-weight: bold;
    }
  }

  .react-select__option--is-selected {
    background-color: rgba(207, 212, 223, 0.2);
    font-weight: bold;
  }

  .react-select__option--is-focused {
    background-color: rgba(207, 212, 223, 0.2);
  }

  .react-select__input {
    & input {
      color: #657496 !important;
    }
  }
`;

const CreatableAsyncPaginateStyle = styled(CreatableAsyncPaginate)`
  .react-select__control {
    border-radius: 4px;
    border: 0;
    box-shadow: none;
    height: 40px;
    position: absolute;
    top: -0px;
    right: 0px;
    left: 0px;
    width: 100%;

    &:hover {
      border-color: #8995b1;
    }

    ${(props) => {
    if (props.error) {
      return `
          border: 1px solid #ff5252 !important;
        `;
    }
  }}
  }
  .react-select__control--is-focused {
    border-color: #8995b1;
  }
  .react-select__control--menu-is-open {
    border-color: #8995b1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .react-select__indicator {
      transform: rotate(180deg);
    }
  }
  .react-select__indicators {
    & > span {
      display: none;
    }
  }
  .react-select__single-value {
    color: #657496 !important;
  }
  .react-select__value-container {
    font-size: 14px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: #afb7c9;
  }
  .react-select__menu-notice {
    font-size: 14px;
    color: #8995b1;
  }
  .react-select__indicator {
    color: #afb7c9;
    transition: transform 0.3s ease-in-out;

    &:hover {
      color: #8995b1;
    }
  }

  .react-select__menu {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: none;
    border: 1px solid #8995b1;
    border-top: 0;
    position: absolute;
    left: 0px;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    font-size: 14px;
    color: #8995b1;
    &:active {
      background-color: rgba(207, 212, 223, 0.2);
      font-weight: bold;
    }
  }

  .react-select__option--is-selected {
    background-color: rgba(207, 212, 223, 0.2);
    font-weight: bold;
  }

  .react-select__option--is-focused {
    background-color: rgba(207, 212, 223, 0.2);
  }

  .react-select__input {
    & input {
      color: #657496 !important;
    }
  }
`;

export default SelectBase;

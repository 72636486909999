import React, {useEffect, useState} from 'react';

const gradient = [
  'rgba(0, 255, 0, 0)',
  'rgb(0, 253, 40)',
  'rgb(0, 250, 60)',
  'rgb(0, 248, 77)',
  'rgb(0, 245, 93)',
  'rgb(0, 242, 107)',
  'rgb(0, 239, 121)',
  'rgb(0, 235, 135)',
  'rgb(0, 232, 149)',
  'rgb(0, 228, 163)',
  'rgb(0, 225, 176)',
  'rgb(0, 221, 190)',
  'rgb(0, 217, 203)',
  'rgb(0, 213, 216)',
  'rgb(0, 209, 229)',
  'rgb(0, 205, 242)',
  'rgb(0, 201, 254)',
  'rgb(0, 197, 255)',
  'rgb(0, 193, 255)',
  'rgb(0, 188, 255)',
  'rgb(0, 184, 255)',
  'rgb(0, 179, 255)',
  'rgb(0, 175, 255)',
  'rgb(0, 170, 255)',
  'rgb(0, 165, 255)',
  'rgb(0, 160, 255)',
  'rgb(0, 155, 255)',
  'rgb(0, 149, 255)',
  'rgb(0, 143, 255)',
  'rgb(0, 137, 255)',
  'rgb(0, 130, 255)',
  'rgb(0, 123, 255)',
  'rgb(0, 115, 255)',
  'rgb(0, 106, 255)',
  'rgb(0, 97, 255)',
  'rgb(0, 86, 255)',
  'rgb(0, 74, 255)',
  'rgb(0, 60, 255)',
  'rgb(0, 41, 255)',
  'rgb(0, 0, 255)',
  'rgb(0, 0, 255)',
  'rgb(68, 0, 250)',
  'rgb(96, 0, 244)',
  'rgb(116, 0, 238)',
  'rgb(133, 0, 232)',
  'rgb(147, 0, 226)',
  'rgb(160, 0, 220)',
  'rgb(171, 0, 213)',
  'rgb(181, 0, 206)',
  'rgb(191, 0, 200)',
  'rgb(199, 0, 193)',
  'rgb(207, 0, 186)',
  'rgb(214, 0, 179)',
  'rgb(220, 0, 172)',
  'rgb(226, 0, 166)',
  'rgb(232, 0, 159)',
  'rgb(237, 0, 152)',
  'rgb(241, 0, 145)',
  'rgb(245, 0, 138)',
  'rgb(249, 0, 132)',
  'rgb(252, 0, 125)',
  'rgb(255, 0, 119)',
  'rgb(255, 0, 112)',
  'rgb(255, 0, 106)',
  'rgb(255, 0, 100)',
  'rgb(255, 0, 94)',
  'rgb(255, 0, 88)',
  'rgb(255, 0, 82)',
  'rgb(255, 0, 76)',
  'rgb(255, 0, 70)',
  'rgb(255, 0, 64)',
  'rgb(255, 0, 59)',
  'rgb(255, 0, 53)',
  'rgb(255, 0, 47)',
  'rgb(255, 0, 42)',
  'rgb(255, 0, 35)',
  'rgb(255, 0, 29)',
  'rgb(255, 0, 21)',
  'rgb(255, 0, 12)',
  'rgb(255, 0, 0)',
];

export default function Heatmap({
  maps,
  map,
  toggleRadius = false,
  toggleOpacity = false,
  toggleGradient = false,
  toggleHeatmap = false,
  points = [],
  withBounds = true
}) {
  const [heatmap, setHeatmap] = useState();
  const [zoomTask, setZoomTask] = useState(0);
  const [zoomRadiusMultiplier, setZoomRadiusMultiplier] = useState(0);
  const [zoomOpacityMultiplier, setZoomOpacityMultiplier] = useState(0);

  const baseRadius = 35;
  const baseOpacity = .5;
  const zoomMultiplierBaseLine = 11;
  const radiusMultiplyRate = .3;
  const opacityMultiplyRate = .08;

  if (!zoomTask) {
    const zoomTaskBuffer = setInterval(() => {
      const zoom = map.getZoom();
      const zoomRadiusMultiplier = Math.max((zoom - zoomMultiplierBaseLine) * radiusMultiplyRate, 0) + 1;
      const zoomOpacityMultiplier = Math.max((zoom - zoomMultiplierBaseLine) * opacityMultiplyRate, 0) + 1;

      setZoomRadiusMultiplier(zoomRadiusMultiplier);
      setZoomOpacityMultiplier(zoomOpacityMultiplier);
    }, 1000);
    setZoomTask(zoomTaskBuffer);
  }
  useEffect(() => {
    if (!heatmap) {
      return;
    }
    heatmap.setMap(heatmap.getMap() ? null : map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleHeatmap]);

  useEffect(() => {
    if (!heatmap) {
      return;
    }
    heatmap.set('gradient', toggleGradient ? null : gradient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleGradient]);

  useEffect(() => {
    if (!heatmap) {
      return;
    }

    const zoom = map.getZoom();
    let radius = null;
    if (!toggleRadius) {
      radius = baseRadius * zoomRadiusMultiplier;
    } else {
      if (zoom < 16)
        radius = 5;
      if (zoom > 17)
        radius = 30;
    }

    heatmap.set('radius', radius);
    heatmap.set('maxIntensity', toggleRadius ? 11 : 110);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRadius, zoomRadiusMultiplier]);

  useEffect(() => {
    if (!heatmap) {
      return;
    }
    heatmap.set('opacity', toggleOpacity ? 0 : baseOpacity * zoomOpacityMultiplier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOpacity, zoomOpacityMultiplier]);

  useEffect(() => {
    if (!heatmap && points instanceof Array) {
      setHeatmap(
        new maps.visualization.HeatmapLayer({
          map,
          data: points
            .map(p => {
              if (p.latitude && p.longitude) {
                return new maps.LatLng(p.latitude, p.longitude);
              }
              return null;
            })
            .filter(Boolean),
        }),
      );

      if (points.length) {
        const bounds = new maps.LatLngBounds();
        for (let i = 0, LtLgLen = points.length; i < LtLgLen; i++) {
          bounds.extend(
            new maps.LatLng(points[i].latitude, points[i].longitude),
          );
        }
        map.fitBounds(bounds);
      }
    }
    if (heatmap && points instanceof Array) {
      heatmap.set(
        'gradient',
        heatmap.get('gradient') ? heatmap.get('gradient') : gradient,
      );
      heatmap.set('radius', baseRadius);
      heatmap.set('opacity', baseOpacity);
      heatmap.setMap(map);
      heatmap.setData(
        points
          .map(p => {
            if (p.latitude && p.longitude) {
              return {location: new maps.LatLng(p.latitude, p.longitude), weight: p.peso}
            }
            return null;
          })
          .filter(Boolean),
      );
      if (points.length) {
        const bounds = new maps.LatLngBounds();
        for (let i = 0, LtLgLen = points.length; i < LtLgLen; i++) {
          bounds.extend(
            new maps.LatLng(points[i].latitude, points[i].longitude),
          );
        }
        map.fitBounds(bounds);
        !withBounds && map.setZoom(14);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  return <></>;
}

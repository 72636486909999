import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import * as Yup from 'yup';
import {Formik, Field} from 'formik';
import {Save} from '@mui/icons-material';
import {Box, Grid, Typography} from '@mui/material';
import AvatarImg from '../assets/images/avatar-default.png';
import {ButtonComponent, ButtonOutlineComponent} from '../components/mui-button';
import {ATUALIZAR_SENHA} from '../graphql/mutation';
import {getImageAuth} from '../utils/get-image';
import InputV2Formik from '../components/input-v2/mui-input-v2-formik';
import {toastNotification} from '../components/toastify';

export const RedefinirSenhaContainer = ({anunciante, onClose}) => {
  const [atualizarSenha, {loading}] = useMutation(ATUALIZAR_SENHA);

  const {nomeFantasia, fotoPerfil} = anunciante;

  const [avatar, setAvatar] = useState(getImageAuth(fotoPerfil));

  const handleClickSubmit = ({senhaAtual, novaSenha}) => {
    if (loading) return;

    atualizarSenha({variables: {senhaAtual, novaSenha}})
      .then(() => {
        onClose();
        toastNotification({message: 'Senha alterada!', type: 'success'})
      })
      .catch(error => toastNotification({message: error.message, type: 'error'}));
  };

  return (
    <Grid container className='styled-scroll-transparent' sx={styles.container}>
      <Formik
        validationSchema={SenhaScheme}
        initialValues={{
          senhaAtual: '',
          novaSenha: '',
          confirmarSenha: '',
        }}
      >
        {({values}) => (
          <Box component="form" style={{height: '100%'}}>
            <Grid container spacing={2} padding={2} sx={styles.formContainer}>
              <Grid container item xs={12} spacing={2} justifyContent={'center'}>
                <Box
                  component="img"
                  src={avatar}
                  onError={() => setAvatar(AvatarImg)}
                  alt={nomeFantasia}
                  sx={styles.avatar}
                />
                <Grid item xs={12}>
                  <Typography textAlign="center">Redefinir Senha</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    type="password"
                    name="senhaAtual"
                    label="Senha atual"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    type="password"
                    name="novaSenha"
                    label="Nova senha"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    type="password"
                    name="confirmarSenha"
                    label="Confirmar nova senha"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <ButtonComponent
                    type="button"
                    disabled={loading}
                    value={'Redefinir Senha'}
                    loading={loading}
                    icon={<Save />}
                    onClick={() => handleClickSubmit(values)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonOutlineComponent
                    value={`Voltar ao perfil`}
                    type="button"
                    onClick={onClose}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </Grid>
  );
};

const SenhaScheme = Yup.object().shape({
  senhaAtual: Yup.string().required('Campo não informado'),
  novaSenha: Yup.string().required('Campo não informado'),
  confirmarSenha: Yup.string()
    .required('Campo não informado')
    .oneOf([Yup.ref('novaSenha'), null], 'As senhas não coincidem'),
});

const styles = {
  container: {
    justifyContent: 'center',
    alignContent: 'flex-start',
    height: theme => `calc(100vh - ${theme.toolbarHeight})`,
    paddingTop: '16px',
    overflow: 'auto',
  },
  formContainer: {
    alignContent: 'space-between',
    height: '100%'
  },
  formTitle: {
    color: '#657496',
    fontSize: '16px',
    textAlign: 'center'
  },
  avatar: {
    background: '#d2d7e2',
    objectFit: 'contain',
    backgroundColor: '#fff',
    minWidth: '90px',
    minHeight: '90px',
    width: '90px',
    height: '90px',
    display: 'block',
    borderRadius: '50%',
    marginTop: theme => theme.space[2],
    marginBottom: '10px',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)',
  }
};
import moment from 'moment';

const Data = {
  hoje: (format = 'DD/MM/YYYY') => {
    return moment().format(format);
  },
  dataFormat: (data, format = 'DD/MM/YYYY') => {
    if (!data) return '';

    return moment(data).format(format);
  },
  dataFinalCampanha: (dataInicio, dias, meses, format) => {
    if (!dataInicio || !dias || !meses) return '';

    const data = moment(dataInicio).add((dias * meses) - 1, 'days');

    if (format)
      return data.format('DD/MM/YYYY');

    return data;
  },
  dataAtualOuFinalCampanha: (dataInicio, dias, meses) => {
    let dataFinal = moment(dataInicio).add((dias * meses) - 1, 'days');
    let hoje = moment();

    if (hoje.diff(dataFinal, 'days') < 0)
      return hoje;

    return dataFinal;
  },
  periodoDaCampanha: (dataInicio, dias, meses) => {
    if (!dataInicio) return 1;

    let inicio = moment(dataInicio);
    let hoje = moment();

    const periodo = parseInt((hoje.diff(inicio, 'days') / dias) + 1);

    if (periodo >= meses)
      return meses;

    return periodo;
  },
  calculaDiasNoPeriodo: (dataInicio, dias, meses, periodo) => {
    if (!dataInicio) return 0;

    if (isNaN(periodo) || periodo === null) {
      return Data.dataAtualOuFinalCampanha(dataInicio, dias, meses).diff(moment(dataInicio), 'days') + 1;
    }

    const hoje = moment();
    const inicio = moment(dataInicio).add(dias * periodo, 'days');
    const fim = moment(dataInicio).add((dias * (periodo + 1)) - 1, 'days');

    if (hoje.diff(fim, 'days') < 0)
      return hoje.diff(inicio, 'days') + 1;

    return fim.diff(inicio, 'days') + 1;
  },
  getDiasPeriodo: (intervalo) => {
    const meses = [];
    const intervalos = [];

    if (!intervalo) return {meses, intervalos};

    const fim = moment(intervalo.fim);
    let data = moment(intervalo.inicio);

    while (fim.diff(data, 'days', true) >= 0) {
      meses.push([data.format('DD'), data.format('ddd')]);
      intervalos.push({
        inicio: data.format('YYYY-MM-DD'),
        fim: data.format('YYYY-MM-DD'),
      });

      data = data.add(1, 'days');
    }

    return {meses, intervalos};
  },
  getPeriodosCampanha: (campanha, isPorPeriodos, periodoSelecionado) => {
    let quantidadeMeses = 0;
    let diasPeriodo = 30;
    let dataInicio = moment();
    let dataFim = moment();

    const intervalos = [];
    const meses = [];
    const periodos = [];

    if (!campanha?.dataInicioVeiculacao) {
      return {
        meses,
        intervalos,
        periodos,
      };
    }

    quantidadeMeses = campanha.quantidadeMeses;
    diasPeriodo = campanha.diasPeriodo;
    dataInicio = moment(campanha.dataInicioVeiculacao);
    dataFim = moment(campanha.dataInicioVeiculacao)
      .add(diasPeriodo * quantidadeMeses, 'days')
      .subtract(1, 'days');

    if (
      campanha &&
      !isPorPeriodos &&
      dataFim.isAfter(moment(campanha.dataInicioVeiculacao).endOf('month'))
    )
      quantidadeMeses++;

    let i = 0;
    while (i < quantidadeMeses && dataInicio.isBefore(dataFim)) {
      const inicio = moment(dataInicio);
      let fim;
      meses.push(`P${i + 1}`);

      if (isPorPeriodos) {
        fim = moment(dataInicio).add(diasPeriodo - 1, 'days');
        dataInicio.add(diasPeriodo, 'days');
      } else {
        fim = moment(dataInicio).endOf('month');
        dataInicio.add(1, 'months').startOf('month');
      }

      if ((i === quantidadeMeses - 1) || fim.isAfter(dataFim)) fim = dataFim;

      periodos.push({
        index: i,
        inicio: inicio,
        fim: fim,
      });

      if (i === quantidadeMeses - 1)
        fim = moment(dataInicio).add(diasPeriodo * 2, 'days').subtract(1, 'days');

      intervalos.push({
        inicio: inicio.format('YYYY-MM-DD'),
        fim: fim.format('YYYY-MM-DD'),
      });

      i++;
    }

    if (periodoSelecionado >= 0 || intervalos.length === 1) {
      return {
        ...Data.getDiasPeriodo(periodos[periodoSelecionado || 0]),
        periodos,
      };
    }

    if (periodoSelecionado >= 0) {
      return {
        meses: [meses[periodoSelecionado]],
        intervalos: [intervalos[periodoSelecionado]],
        periodos,
      };
    }

    return {
      meses,
      intervalos,
      periodos,
    };
  }
};

export default Data;

import {FormControl, InputLabel, OutlinedInput, FormHelperText, } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import InputV2 from '../input-v2/mui-input-v2';
import SelectBase from './mui-select-base';

const SelectStyled = styled(SelectBase)(() => { });

const Select = ({
  label,
  className,
  mensagemSuporte,
  value,
  disabled,
  inputAdornment,
  ...props
}) => {
  if (disabled) {
    return (
      <InputV2
        label={label}
        value={value?.label}
        disabled
        {...props}
        shrink={value ? true : undefined}
      />
    );
  }

  return (
    <FormControl
      fullWidth
      className={'select-input'}
      size="small"
    >
      <InputLabel
        variant="outlined"
        htmlFor="input-v2"
        size="small"
        id="input-label-v2"
        shrink={value ? true : undefined}
        className={`${props.errors ? 'error' : ''}`}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="input-v2"
        className={`${value === 0 || value ? 'withValue' : ''}${props.errors ? '-error' : ''}`}
        inputComponent={SelectWithRef}
        inputProps={{
          placeholder: '',
          value: value || '',
          ...props,
        }}
        value={value || ''}
        label={label}
        notched={value ? true : undefined}
      />
      {props.errors && (
        <FormHelperText
          sx={{color: (theme) => theme.palette.cancel.main}}
          id="support-message-id"
        >
          {mensagemSuporte}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const SelectWithRef = React.forwardRef((props, ref) => (
  <SelectStyled innerRef={ref} {...props} />
));

export default React.memo(Select);

import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

let infoWindow = null;

const handleCloseInfoWindow = currentInfoWindow => {
  currentInfoWindow.close();
  infoWindow = null;
};

export default function InfoWindow({
  marker,
  maps,
  map,
  visible = false,
  children,
}) {
  const [containerElement, setContainerElement] = useState();

  useEffect(() => {
    setContainerElement(document.createElement('div'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!infoWindow) {
      infoWindow = new maps.InfoWindow();
    }

    if (infoWindow && visible) {
      infoWindow.setContent(containerElement);
      infoWindow.open(map, marker);
    }

    if (infoWindow && !visible) {
      handleCloseInfoWindow(infoWindow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (containerElement) {
    return createPortal(React.Children.only(children), containerElement);
  }

  return <></>;
}

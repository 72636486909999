import React from 'react';
import {
  BurstModeRounded,
  CampaignRounded,
  MapRounded,
} from '@mui/icons-material';
import {
  HOME_ROUTE,
  QUILOMETRAGEM_ROUTE,
  IMPACTOS_ROUTE,
  PASSAGEIROS_ROUTE,
  IMAGENS_ROUTE,
  MAPA_DE_CALOR_ROUTE,
} from '../constants/routes';

const styles = {
  campanhaIcon: {
    width: '30px',
    height: '30px',
    marginRight: '-6px',
  }
};

const routes = [
  {
    title: 'Campanhas',
    icon: <CampaignRounded sx={styles.campanhaIcon} />,
    options: [
      {
        title: 'Resumo',
        route: HOME_ROUTE,
      },
      {
        title: 'Quilometragem',
        route: QUILOMETRAGEM_ROUTE,
      },
      {
        title: 'Impactos',
        route: IMPACTOS_ROUTE,
      },
      {
        title: 'Passageiros',
        route: PASSAGEIROS_ROUTE,
      },
    ]
  },
  {
    title: 'Check-in / Check-out',
    route: IMAGENS_ROUTE,
    icon: <BurstModeRounded />,
  },
  {
    title: 'Mapa de calor',
    route: MAPA_DE_CALOR_ROUTE,
    icon: <MapRounded />,
  },
]

const SidebarRoutes = {
  getSidebarRoutes: () => routes
}

export default SidebarRoutes;
import React from 'react';
import {ChevronLeft} from '@mui/icons-material';
import {Grid, Typography} from '@mui/material';

const Header = ({onBackButton, hiddenBackButton = false}) => (
  <Grid container sx={styles.container}>
    <Grid sx={styles.headerTitleContainer}>
      <Grid component="a"
        onClick={onBackButton}
        className={hiddenBackButton ? 'hidden' : 'visible'}
        sx={styles.headerBackButton}
      >
        <ChevronLeft sx={styles.icon} />
      </Grid>
      <Typography sx={styles.headerTitle}>Anunciante</Typography>
    </Grid>
  </Grid>
);

const styles = {
  container: {
    width: '100%',
    height: '90px',
    borderBottom: '1px solid #d5dce3',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',

    '@media (min-width: 1024px)': {
      justifyContent: 'space-between',
      paddingRight: '100px',
    }
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '@media (min-width: 1024px)': {
      paddingLeft: '84px',
    }
  },
  headerBackButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '20px',
    height: '32px',

    '@media (min-width: 1024px)': {
      marginRight: '32px',
    },
    '&.visible': {
      display: 'block',
      '@media (min-width: 1024px)': {
        visibility: 'visible',
      }
    },
    '&.hidden': {
      display: 'none',
      '@media (min-width: 1024px)': {
        display: 'block',
        visibility: 'hidden',
      }
    }
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#0091ff',
    marginBottom: 0,
    marginTop: 0,
  },
  icon: {
    fontSize: '32px',
    color: '#0091ff'
  }
};

export default Header;

import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import {useLazyQuery} from '@apollo/client';
import {PrintRounded} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {
  TOTAL_IMPACTOS_CAMPANHA,
  IMPACTOS_PERIODOS_CAMPANHA,
  IMPACTOS_HEATMAP_CAMPANHA,
} from '../../graphql/queries';
import Data from '../../utils/data';
import {RoundTooltipButton} from '../../components/mui-button';
import ImpressaoImpactos from './impressao-impactos';
import GraficoBarras from '../../components/graficos/grafico-barras';
import GraficoTotais from '../../components/graficos/grafico-totais';
import Number from '../../utils/number';
import GraficoDiaSemana from '../../components/graficos/grafico-dia-semana';
import GraficoPeriodoDia from '../../components/graficos/grafico-periodo-dia';
import GraficoHeatmap from '../../components/graficos/grafico-heatmap';

const pageStyle = `
  @page { 
    size: A4; 
    margin: 0mm 5mm 0mm 5mm;
  }

  @media print {
    .chartjs-size-monitor{
      position: fixed !important;
    }
    html, body, #root {
      width: 297mm;
      height: 420mm;
      background: #fff !important;
    }
  }

  @media all {
    .report-hidden {
      display: block !important;
    }
    div {
      -webkit-print-color-adjust: exact; 
    },
  }
`;

const ImpactosEstimados = () => {
  const {campanhaSelected: campanha, cidadeSelected: campanhaCidade} =
    useSelector((store) => store.Toolbar);
  const {periodoSelecionado, isPorPeriodos} = useSelector(
    (store) => store.Periodos,
  );

  const componentRef = useRef();

  const [loading, setLoading] = useState(false);
  const [totalImpactados, setTotalImpactados] = useState(0);
  const [custoPorImpacto, setCustoPorImpacto] = useState(0);
  const [impactosPeriodos, setImpactosPeriodos] = useState(undefined);
  const [impactosDiaSemana, setImpactosDiaSemana] = useState([]);
  const [impactosPeriodoDia, setImpactosPeriodoDia] = useState([]);
  const [impactosHeatmap, setImpactosHeatmap] = useState([]);

  const {intervalos, meses, periodos} = Data.getPeriodosCampanha(
    campanha,
    isPorPeriodos,
    periodoSelecionado,
  );

  const [loadTotais, totaisQuery] = useLazyQuery(TOTAL_IMPACTOS_CAMPANHA);
  const [loadImpactosHeatmap, impactosHeatmapQuery] = useLazyQuery(
    IMPACTOS_HEATMAP_CAMPANHA,
  );
  const [loadPeriodos, periodosQuery] = useLazyQuery(
    IMPACTOS_PERIODOS_CAMPANHA,
  );

  useEffect(() => {
    if (!campanha) return;

    setImpactosPeriodos(undefined);

    const dataInicio =
      periodos[periodoSelecionado]?.inicio?.format('YYYY-MM-DD');
    const dataFim = periodos[periodoSelecionado]?.fim?.format('YYYY-MM-DD');

    loadTotais({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id
          ? {
              id: campanhaCidade?.id,
            }
          : null,
        dataInicio,
        dataFim,
      },
    });

    loadImpactosHeatmap({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id
          ? {
              id: campanhaCidade?.id,
            }
          : null,
        dataInicio,
        dataFim,
      },
    });

    loadPeriodos({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id
          ? {
              id: campanhaCidade?.id,
            }
          : null,
        intervalos: intervalos,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, campanhaCidade, isPorPeriodos, periodoSelecionado]);

  useEffect(() => {
    if (periodosQuery.loading) return;

    setImpactosPeriodos(periodosQuery.data?.impactos);
  }, [periodosQuery]);

  useEffect(() => {
    if (totaisQuery.loading) return;

    setTotalImpactados(parseInt(totaisQuery.data?.impactos || 0));
    setCustoPorImpacto(totaisQuery.data?.custo || 0);
    setImpactosDiaSemana(totaisQuery.data?.impactosDiaSemana);
    setImpactosPeriodoDia(totaisQuery.data?.impactosPeriodoDia);
  }, [totaisQuery]);

  useEffect(() => {
    if (impactosHeatmapQuery.loading) return;

    setImpactosHeatmap(impactosHeatmapQuery.data?.impactosHeatmap || []);
  }, [impactosHeatmapQuery]);

  return (
    <Grid item xs={12} sx={styles.graphsContainer}>
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Impactos - ${campanha?.anunciante?.nomeFantasia}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintRounded />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <ImpactosDashboard
        meses={meses}
        campanha={campanha}
        campanhaCidade={campanhaCidade}
        totalImpactados={totalImpactados}
        custoPorImpacto={custoPorImpacto}
        impactosPeriodos={impactosPeriodos}
        impactosHeatmap={impactosHeatmap}
        impactosDiaSemana={impactosDiaSemana}
        impactosPeriodoDia={impactosPeriodoDia}
        periodoSelecionado={periodos[periodoSelecionado]}
        periodosLoading={periodosQuery.loading}
        impactosPeriodoDiaLoading={totaisQuery.loading}
        impactosHeatmapLoading={impactosHeatmapQuery.loading}
      />
      <div style={{overflow: 'hidden', height: 0}}>
        <Grid style={{background: '#fff'}} ref={componentRef}>
          <ImpressaoImpactos
            campanha={campanha}
            campanhaCidade={campanhaCidade}
            periodos={periodos}
            totalImpactados={totalImpactados}
            custoPorImpacto={custoPorImpacto}
            impactosPeriodos={impactosPeriodos}
            impactosHeatmap={impactosHeatmap}
            impactosDiaSemana={impactosDiaSemana}
            impactosPeriodoDia={impactosPeriodoDia}
            periodoSelecionado={periodos[periodoSelecionado]}
            meses={meses}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const ImpactosDashboard = ({
  campanha,
  campanhaCidade,
  totalImpactados,
  custoPorImpacto,
  periodoSelecionado,
  impactosPeriodos,
  impactosHeatmap,
  impactosDiaSemana,
  impactosPeriodoDia,
  impactosHeatmapLoading,
  impactosPeriodoDiaLoading,
  periodosLoading,
  meses,
}) => {
  const [impactosMediaPeriodo, setImpactosMediaPeriodo] = useState(0);
  const [impactosMediaDia, setImpactosMediaDia] = useState(0);

  useEffect(() => {
    if (!campanha) return;

    const dias = Data.calculaDiasNoPeriodo(
      campanha.dataInicioVeiculacao,
      campanha.diasPeriodo,
      campanha.quantidadeMeses,
      periodoSelecionado?.index,
    );
    const periodos = periodoSelecionado
      ? 1
      : Data.periodoDaCampanha(
          campanha.dataInicioVeiculacao,
          campanha.diasPeriodo,
          campanha.quantidadeMeses,
        );

    setImpactosMediaPeriodo(
      totalImpactados /
        periodos /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos),
    );
    setImpactosMediaDia(
      totalImpactados /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos) /
        (dias || 1),
    );
  }, [campanha, campanhaCidade, periodoSelecionado, totalImpactados]);

  return (
    <Grid container>
      <GraficoTotais
        titulo="Impactos estimados"
        totais={[
          {
            label: 'Total',
            value: `${Number.format(Number.safeParseInt(totalImpactados))}`,
          },
          {
            label: 'Média / Veículo / Período',
            value: `${Number.format(
              Number.safeParseInt(impactosMediaPeriodo),
            )}`,
          },
          {
            label: 'Média / Veículo / Dia',
            value: `${Number.format(Number.safeParseInt(impactosMediaDia))}`,
          },
          {
            label: 'CPM',
            value: `${Number.currencyFormatDigitos(
              (custoPorImpacto || 0) * 1000,
              4,
            )}`,
          },
          {
            label: 'Custo individual',
            value: `${Number.currencyFormatDigitos(custoPorImpacto, 4)}`,
          },
        ]}
      />
      <GraficoBarras
        campanha={campanha}
        meses={meses}
        periodos={impactosPeriodos}
        periodoSelecionado={periodoSelecionado}
        loading={periodosLoading}
        titulo="Impactos estimados"
      />
      <GraficoDiaSemana
        valorTotal={totalImpactados}
        dias={impactosDiaSemana}
        titulo="Impactos estimados"
        loading={impactosPeriodoDiaLoading}
      />
      <GraficoPeriodoDia
        campanha={campanha}
        periodos={impactosPeriodoDia}
        valorTotal={totalImpactados}
        loading={impactosPeriodoDiaLoading}
        titulo="Impactos estimados"
      />
      <GraficoHeatmap
        campanha={campanha}
        heatmap={impactosHeatmap}
        loading={impactosHeatmapLoading}
        titulo="Impactos estimados"
      />
    </Grid>
  );
};

const styles = {
  graphsContainer: {
    overflowY: 'auto',
    padding: '16px',
    height: (theme) => theme.contentHeightMd,

    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
  },
};

export default ImpactosEstimados;

import {Person} from '@mui/icons-material';
import {Box, Grid, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {getImageAuth} from '../utils/get-image';
import string from '../utils/string';

export const CondutorContainer = ({anuncio = {}, onClickImagem}) => {
  const [fotoPerfil, setFotoPerfil] = useState(
    `${anuncio?.motorista?.fotoPerfil}`,
  );

  useEffect(() => {
    setFotoPerfil(`${anuncio?.motorista?.fotoPerfil}`);
  }, [anuncio]);

  return (
    <Grid item sx={styles.container}>
      <Grid container item xs={12} columnGap={2} alignItems={'center'}>
        {fotoPerfil ? (
          <Box
            component="img"
            src={getImageAuth(fotoPerfil)}
            onError={() => setFotoPerfil(null)}
            alt="foto-motorista"
            onClick={() =>
              onClickImagem &&
              onClickImagem({
                id: fotoPerfil,
                titulo: 'Foto Condutor',
              })
            }
            sx={styles.avatar}
          />
        ) : (
          <Person sx={[styles.avatar, {padding: 1}]} />
        )}
        <Grid item sx={styles.nomeContainer}>
          <Typography sx={styles.infoNome}>
            {anuncio?.motorista?.nome || ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <Typography sx={styles.infoLabel}>
          Veículo:{' '}
          <Typography component={'span'}>
            {anuncio?.motorista?.veiculoAtual?.modelo?.marca?.nome} -{' '}
            {anuncio?.motorista?.veiculoAtual?.modelo?.nome}
          </Typography>
        </Typography>
        <Typography sx={styles.infoLabel}>
          Data instalação:{' '}
          <Typography component={'span'}>
            {anuncio?.dataInstalacao
              ? moment(anuncio.dataInstalacao).format('DD/MM/YYYY')
              : ' '}
          </Typography>
        </Typography>
        <Typography sx={styles.infoLabel}>
          Placa:{' '}
          <Typography component={'span'}>
            {string.formatPlaca(anuncio?.motorista?.veiculoAtual?.placa)}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 8px',
  },
  nomeContainer: {
    width: 'calc(100% - 70px)',
  },
  infoNome: {
    fontSize: 16,
    fontFamily: 'Poppins',
    color: '#262626',
    fontWeight: '700',
  },
  infoLabel: {
    fontSize: 12,
    color: '#262626',
    span: {
      fontSize: 'inherit',
      fontWeight: '700',
      marginLeft: '6px',
    },
  },
  avatar: {
    background: '#d2d7e2',
    color: '#fff',
    minWidth: '50px',
    minHeight: '50px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
};

import React from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import styled from '@emotion/styled';
import {LOGIN_ROUTE, ESQUECI_SENHA_ROUTE} from '../constants/routes';
import LoginView from "../views/auth/login";
import RecuperarSenhaView from "../views/auth/recuperar-senha";
import BannerBgImg from '../assets/images/bannerbg-gradient-mask@3x.png';
import KmMidiaLogoImg from '../assets/images/km-midia@3x.png';

const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const BannerContainer = styled.div`
  width: 50%;
  height: 100vh;
  background: url(${BannerBgImg}) top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
    height: 150px;
    background: url(${BannerBgImg}) center -400px no-repeat;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const KmMidiaLogo = styled.img`
  height: 60px;

  @media (min-width: 1024px) {
    height: auto;
    margin-top: 6%;
  }
`;

export const RouterAuthComponent = () => (
  <FullWrapper>
    <BannerContainer>
      <KmMidiaLogo src={KmMidiaLogoImg} alt="kmmidia" />
    </BannerContainer>
    <Container>
      <Router style={{height: '100%'}}>
        <Routes>
          <Route path={LOGIN_ROUTE} element={<LoginView />} />
          <Route path={ESQUECI_SENHA_ROUTE} element={<RecuperarSenhaView />} />
          <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
        </Routes>
      </Router>
    </Container>
  </FullWrapper>
)

import React from 'react';
import {useSelector} from 'react-redux';
import {Grid, Paper} from '@mui/material';
import KmRodado from './quilometros';
import PeriodosHeadDashboard from '../../components/periodos';

const Quilometros = () => {
  const {campanhaSelected, cidadeSelected} = useSelector((store) => store.Toolbar);

  return (
    <Grid id="page-container" container>
      <PeriodosHeadDashboard />
      <Paper sx={styles.graphContainer}>
        <KmRodado campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
      </Paper>
    </Grid>
  );
};

const styles = {
  graphContainer: {
    minHeight: 'calc(100vh - 115px)',
    width: 'inherit'
  }
};

export default Quilometros;

import React from 'react';
import {Typography, Grid, Modal, Box} from '@mui/material';
import {Close} from '@mui/icons-material';

const VideoModal = ({onClose, openVideo}) => {
  return (
    <Modal
      open={openVideo}
      onClose={onClose}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}
    >
      <Box sx={styles.containerVideo}>
        <Grid sx={styles.header}>
          <Grid sx={styles.containerTitulo}>
            <Typography sx={styles.titulo}>Veja o tutorial</Typography>
            <Typography sx={styles.subtitle}>
              Conhecendo o painel do Km
            </Typography>
          </Grid>
          <Close onClick={onClose} sx={{cursor: 'pointer', color: '#404040'}} />
        </Grid>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/YRKQpVGj4yQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{borderRadius: '6px'}}
        />
      </Box>
    </Modal >
  );
};
const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: (theme) => theme.zIndex.modal,
  },
  containerVideo: {
    width: '39.1125%',
    height: '62.1888%',
    background: '#ffff',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '2.31063px 3.08083px 10.0127px rgba(0, 131, 230, 0.5)',
    borderRadius: '6.16167px',
    zIndex: 2
  },
  containerTitulo: {
    marginBottom: '19px',
  },
  titulo: {
    fontFamily: 'AktivGrotesk-XBold',
    fontSize: '18.49px',
    lineHeight: '22.74px',
    color: '#404040',
  },
  subtitle: {
    fontFamily: 'AktivGrotesk',
    fontWeight: 400,
    fontSize: '12.32px',
    LineHeight: '14.33px',
    color: '#404040',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px'
  },
};

export default VideoModal;

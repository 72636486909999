import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik, Field} from 'formik';
import {useMutation} from '@apollo/client';
import {Box, Grid} from '@mui/material';
import {Save} from '@mui/icons-material';
import {ButtonComponent, ButtonOutlineComponent} from '../components/mui-button';
import {ATUALIZAR_ANUNCIANTE} from '../graphql/mutation';
import InputV2Formik from '../components/input-v2/mui-input-v2-formik';
import {toastNotification} from '../components/toastify';
import UploadableFotoPerfil from '../components/mui-uploadable-foto-perfil';
import {uploadAmazonS3} from '../services/upload-service';

export const AtualizaPerfilContainer = ({anunciante = {}, onClickRedefinirSenha}) => {
  const {fotoPerfil, telefone, nomeRazao, nomeFantasia, cnpj, usuario = {}} = anunciante;
  const {email} = usuario;

  const [avatar, setAvatar] = useState(fotoPerfil);
  const [uploadImage, setUploadImage] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [atualizarAnunciante, {loadingQuery}] = useMutation(ATUALIZAR_ANUNCIANTE);

  useEffect(() => {
    setAvatar(fotoPerfil);
    // eslint-disable-next-line
  }, [anunciante]);

  const handleChangeUploadImage = (e) => {
    const file = Array.from(e.target.files)[0];
    setUploadImage(file);
    handlePreviewImage(file);
  }

  const handlePreviewImage = (file) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleSubmitClick = async (values) => {
    if (loading || loadingQuery) return;
    setLoading(true);

    let uploadData = null;
    try {
      if (uploadImage) {
        uploadData = await uploadAmazonS3(uploadImage);
      }
    } catch (error) {
      console.log(error);
    }

    atualizarAnunciante({
      variables: {
        anunciante: {
          ...values,
          fotoPerfil: uploadData?.id || anunciante.fotoPerfil,
        },
      },
    })
      .then(() => toastNotification({message: 'Dados atualizados com sucesso!', type: 'success'}))
      .catch((error) => toastNotification({message: error.message, type: 'error'}))
      .finally(() => setLoading(false));
  }

  return (
    <Grid container className='styled-scroll-transparent' sx={styles.container}>
      <Formik
        validationSchema={UserScheme}
        initialValues={{telefone, nomeRazao, nomeFantasia, cnpj, emailUsuario: email}}
      >
        {({values}) => (
          <Box component="form" style={{height: '100%'}}>
            <Grid container spacing={2} padding={2} sx={styles.formContainer}>
              <Grid container item xs={12} spacing={2} justifyContent={'center'}>
                <UploadableFotoPerfil
                  image={previewImage || avatar}
                  setImage={setAvatar}
                  handleChangeUploadImage={handleChangeUploadImage}
                  sx={styles.avatar}
                />
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="emailUsuario"
                    label="E-mail"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="nomeRazao"
                    label="Razão social"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="nomeFantasia"
                    label="Nome fantasia"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="cnpj"
                    label="CNPJ"
                    mask="cnpj"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputV2Formik}
                    name="telefone"
                    label="Telefone"
                    mask="phone"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <ButtonComponent
                    type="button"
                    disabled={loading}
                    value={'Atualizar dados'}
                    loading={loading}
                    icon={<Save />}
                    onClick={() => handleSubmitClick(values)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonOutlineComponent
                    value={`Redefinir Senha`}
                    type="button"
                    disabled={loading}
                    onClick={onClickRedefinirSenha}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </Grid>
  );
};

const UserScheme = Yup.object().shape({
  emailUsuario: Yup.string()
    .email('Email inválido')
    .required('Campo não informado'),
  telefone: Yup.string().required('Campo não informado'),
  cnpj: Yup.string().required('Campo não informado'),
  nomeRazao: Yup.string().required('Campo não informado'),
});

const styles = {
  container: {
    justifyContent: 'center',
    alignContent: 'flex-start',
    height: theme => `calc(100vh - ${theme.toolbarHeight})`,
    paddingTop: '16px',
    overflow: 'auto',
  },
  formContainer: {
    alignContent: 'space-between',
    height: '100%'
  },
  avatar: {
    minWidth: '90px',
    minHeight: '90px',
    width: '90px',
    height: '90px',
    display: 'block',
    borderRadius: '50%',
    marginTop: theme => theme.space[2],
    marginBottom: '10px',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)',
  }
};
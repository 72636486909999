// eslint-disable-next-line no-extend-native
String.prototype.format = function (mask) {
  if (mask == null || this == null) return this;

  let formatted = '';
  let next = 0;
  let ignore = 0;
  for (let i = 0; i < mask.length; i++) {
    if (i - ignore >= this.length) {
      break;
    }
    if (mask[i] === '#') {
      formatted += this[next++];
    } else {
      formatted += mask[i];
      ignore++;
    }
  }

  return formatted;
};

// eslint-disable-next-line no-extend-native
String.prototype.removeSpecialChars = function () {
  if (this.length < 1) {
    return this
  }

  return this.replace(/[^A-Z0-9]+/gi, '');
};

// eslint-disable-next-line no-extend-native
String.prototype.currencyMaskToFloat = function () {
  if (this.trim().length > 0) {
    const newValue = this.replace(/[^,0-9]+/gi, '').replace(',', '.');

    return parseFloat(newValue);
  }

  return 0;
};

// eslint-disable-next-line no-extend-native
String.prototype.validaCPF = function () {
  if (this.isEmpty()) {
    return false;
  }

  const CPFS_INVALIDOS = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ];
  let cpf = this.removeSpecialChars();
  if (CPFS_INVALIDOS.indexOf(cpf) > -1) {
    return false;
  }

  let soma = 0;
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  let resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }


  return resto === parseInt(cpf.substring(10, 11));
};

// eslint-disable-next-line no-extend-native
String.prototype.validaTelefone = function () {
  if (this.isEmpty()) {
    return false;
  }

  const text = this.removeSpecialChars();
  const length = text.trim().length;

  return length >= 10 && length <= 11;
};

// eslint-disable-next-line no-extend-native
String.prototype.stringify = function (objFromJson) {
  if (Array.isArray(objFromJson)) {
    return objFromJson.map(item => this.stringify(item));
  } else if (typeof objFromJson !== 'object') {
    // not an object, stringify using native function
    return JSON.stringify(objFromJson);
  }

  let props = Object.keys(objFromJson)
    .map(key =>
      objFromJson[key] ? `${key}:${this.stringify(objFromJson[key])}` : ''
    )
    .join(',');

  return `{${props}}`;
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.toLowerCase().charAt(0).toUpperCase() + this.slice(1);
};

// eslint-disable-next-line no-extend-native
String.prototype.stringToLowerCase = function (index = 0) {
  return this.charAt(index) + this.slice(1).toLowerCase();
};

// eslint-disable-next-line no-extend-native
String.prototype.isEmpty = function () {
  return this.trim().length === 0;
};

// eslint-disable-next-line no-extend-native
String.prototype.truncate = function (length = 100, ending = '...') {
  if (this.length > length) {
    return this.substring(0, length - ending.length) + ending;
  }

  return this;
};

// eslint-disable-next-line no-extend-native
String.prototype.removeSpecialChars = function () {
  if (!this.length) {
    return this;
  }

  return this.replace(/[^A-Z0-9]+/gi, '');
};

// eslint-disable-next-line no-extend-native
String.prototype.ucfirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const StringUtils = {
  isEmpty(text) {
    if (typeof text !== "string") {
      return true;
    }

    if (text.trim().length === 0) {
      return true;
    }

    return false;
  },
  removerPreposicaoNome(nome) {
    return nome.replace(/ dos | das | dos | das | de | da /gi, ' ');
  },
  abreviarNome(text, length = 25) {
    if (this.length <= length) {
      return this;
    }
    const nomeArray = this.removerPreposicaoNome(text).trim().split(' ');

    const primeiroNome = nomeArray.shift();
    const ultimoNome = nomeArray.pop();

    var abreviados = '';
    nomeArray.forEach((string) => {
      if (string) {
        abreviados = `${abreviados} ${string[0]?.toLocaleUpperCase()}.`
      }
    })

    return `${primeiroNome.capitalize()} ${abreviados} ${ultimoNome.capitalize()}`
  },
  formatPlaca(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    return value.replace(' ', '').replace('-', '').toUpperCase()
  }
};

export default StringUtils;

import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Grid} from '@mui/material';
import {ContatoContainer} from '../containers/contato';
import {TOTAL_CAMPANHA_POR_CIDADE} from '../graphql/queries';
import MapaCidades from '../containers/mapas/mapa-cidades';

const Contato = () => {
  const localizacoesQuery = useQuery(TOTAL_CAMPANHA_POR_CIDADE);

  const [localizacoes, setLocalizacoes] = useState([]);

  useEffect(() => {
    if (localizacoesQuery.loading || localizacoesQuery.error) return;

    setLocalizacoes(localizacoesQuery.data.totais || []);
  }, [localizacoesQuery]);

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={4} md={3} sx={styles.formContainer}>
        <ContatoContainer />
      </Grid>
      <Grid item xs={8} md={9}>
        <MapaCidades sx={styles.mapa} localizacoes={localizacoes} />
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    width: '100%'
  },
  formContainer: {
    backgroundColor: theme => theme.palette.paterns.white,
    boxShadow: '0px 6px 12px rgb(0 0 0 / 20%)',
    zIndex: 2
  },
  mapa: {
    height: 'calc(100vh - 61px)'
  }
};

export default Contato;

import React, {useState} from 'react';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {Formik, Field} from 'formik';
import {Box, Grid, TextField, Typography} from '@mui/material';
import Header from './components/header';
import Footer from './components/footer';
import {LOGIN_ROUTE} from '../../constants/routes';
import {redefinirSenha as redefinirSenhaService} from '../../redux/Auth/services';
import {toastNotification} from '../../components/toastify';
import {ButtonComponent} from '../../components/mui-button';

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmitEmail = async ({email}) => {
    if (loading) return;

    redefinirSenha(email);
  };

  const redefinirSenha = (email) => {
    setLoading(true);

    redefinirSenhaService(email)
      .then((message) => toastNotification({message, type: 'success'}))
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Grid sx={styles.container}>
      <Header onBackButton={() => navigate(LOGIN_ROUTE)} />
      <Grid sx={styles.content}>
        <Formik
          validationSchema={RecuperarSenhaScheme}
          initialValues={{email: ''}}
          onSubmit={handleSubmitEmail}>
          {({handleSubmit, setFieldValue}) => (
            <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
              <Typography sx={styles.pageTitle}>Recuperar senha</Typography>
              <Typography sx={styles.pageSubtitle}>
                Informe seu email de cadastro
              </Typography>
              <Grid container direction="column">
                <Field
                  component={TextField}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onChange={({target}) => setFieldValue('email', target.value)}
                />
                <ButtonComponent
                  type="submit"
                  disabled={loading}
                  value={'Recuperar senha'}
                  sx={styles.button}
                  loading={loading}
                />
                <Typography
                  sx={styles.voltarText}
                  onClick={() => navigate(LOGIN_ROUTE)}>
                  Retornar para o Login
                </Typography>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
      <Footer />
    </Grid>
  );
};

const RecuperarSenhaScheme = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo não informado'),
});

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    paddingLeft: '130px',
    width: '545px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      width: '100%',
      paddingLeft: 0,
    },
  },
  form: {
    width: '100%',
    maxWidth: '510px',
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#262626',
    marginTop: 0,
  },
  pageSubtitle: {
    color: '#657496',
    fontSize: '16px',
  },
  button: {
    margin: '30px 0',
  },
  voltarText: {
    color: '#0083e6',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '30px',
  },
};

export default RecuperarSenha;

import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import {useLazyQuery} from '@apollo/client';
import {PrintRounded} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {
  TOTAL_CORRIDAS_CAMPANHA,
  CORRIDAS_PERIODOS_CAMPANHA,
} from '../../graphql/queries';
import Data from '../../utils/data';
import {RoundTooltipButton} from '../../components/mui-button';
import ImpressaoPassageiros from './impressao-passageiros';
import GraficoBarras from '../../components/graficos/grafico-barras';
import GraficoDiaSemana from '../../components/graficos/grafico-dia-semana';
import GraficoPeriodoDia from '../../components/graficos/grafico-periodo-dia';
import Number from '../../utils/number';
import GraficoTotais from '../../components/graficos/grafico-totais';

const pageStyle = `
  @page { 
    size: A4; 
    margin: 0mm 5mm 0mm 5mm;
  }

  @media print {
    .chartjs-size-monitor{
      position: fixed !important;
    }
    html, body, #root {
      width: 297mm;
      height: 420mm;
      background: #fff !important;
    }
  }

  @media all {
    .report-hidden {
      display: block !important
    }
    div {
      -webkit-print-color-adjust: exact; 
    },
  }
`;

const PassageirosEstimados = () => {
  const {campanhaSelected: campanha, cidadeSelected: campanhaCidade} =
    useSelector((store) => store.Toolbar);
  const {periodoSelecionado, isPorPeriodos} = useSelector(
    (store) => store.Periodos,
  );

  const componentRef = useRef();

  const [loading, setLoading] = useState(false);
  const [totalCorridas, setTotalCorridas] = useState(0);
  const [totalPassageiros, setTotalPassageiros] = useState(0);
  const [corridasPeriodos, setCorridasPeriodos] = useState(undefined);
  const [corridasDiaSemana, setCorridasDiaSemana] = useState([]);
  const [corridasPeriodoDia, setCorridasPeriodoDia] = useState([]);

  const {intervalos, meses, periodos} = Data.getPeriodosCampanha(
    campanha,
    isPorPeriodos,
    periodoSelecionado,
  );

  const [loadTotais, totaisQuery] = useLazyQuery(TOTAL_CORRIDAS_CAMPANHA);
  const [loadPeriodos, periodosQuery] = useLazyQuery(
    CORRIDAS_PERIODOS_CAMPANHA,
  );

  useEffect(() => {
    if (!campanha) return;

    setCorridasPeriodos(undefined);

    const dataInicio =
      periodos[periodoSelecionado]?.inicio?.format('YYYY-MM-DD');
    const dataFim = periodos[periodoSelecionado]?.fim?.format('YYYY-MM-DD');

    loadTotais({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id
          ? {
              id: campanhaCidade?.id,
            }
          : null,
        dataInicio,
        dataFim,
      },
    });

    loadPeriodos({
      variables: {
        campanha: {
          id: campanha.id,
        },
        campanhaCidade: campanhaCidade?.id
          ? {
              id: campanhaCidade?.id,
            }
          : null,
        intervalos: intervalos,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, campanhaCidade, isPorPeriodos, periodoSelecionado]);

  useEffect(() => {
    if (periodosQuery.loading) return;

    setCorridasPeriodos(periodosQuery.data?.corridas);
  }, [periodosQuery]);

  useEffect(() => {
    if (totaisQuery.loading) return;

    setTotalCorridas(totaisQuery.data?.corridasTotal);
    setTotalPassageiros(parseInt(totaisQuery.data?.corridasTotal * 1.4));

    setCorridasPeriodoDia(totaisQuery.data?.corridasPeriodoDia);
    setCorridasDiaSemana(totaisQuery.data?.corridasDiaSemana);
  }, [totaisQuery]);

  return (
    <Grid
      item
      xs={12}
      sx={styles.graphsContainer}
      className="styled-scroll-transparent">
      <ReactToPrint
        pageStyle={pageStyle}
        documentTitle={`KM Mídia - Passageiros - ${campanha?.anunciante?.nomeFantasia}`}
        onBeforePrint={() => setLoading(false)}
        onBeforeGetContent={() => setLoading(true)}
        trigger={() => (
          <RoundTooltipButton
            size="small"
            id="icon-toolbar"
            title={'Imprimir'}
            icon={<PrintRounded />}
            loading={loading}
            loadingColor="grey"
          />
        )}
        content={() => componentRef.current}
      />
      <PassageirosDashboard
        campanha={campanha}
        campanhaCidade={campanhaCidade}
        corridasPeriodos={corridasPeriodos}
        totalPassageiros={totalPassageiros}
        totalCorridas={totalCorridas}
        periodoSelecionado={periodos[periodoSelecionado]}
        meses={meses}
        corridasDiaSemana={corridasDiaSemana}
        corridasPeriodoDia={corridasPeriodoDia}
        periodosLoading={periodosQuery.loading}
        corridasPeriodoDiaLoading={totaisQuery.loading}
      />
      <div style={{overflow: 'hidden', height: 0}}>
        <Grid style={{background: '#fff'}} ref={componentRef}>
          <ImpressaoPassageiros
            campanha={campanha}
            campanhaCidade={campanhaCidade}
            periodos={periodos}
            corridasPeriodos={corridasPeriodos}
            totalPassageiros={totalPassageiros}
            totalCorridas={totalCorridas}
            periodoSelecionado={periodos[periodoSelecionado]}
            meses={meses}
            corridasDiaSemana={corridasDiaSemana}
            corridasPeriodoDia={corridasPeriodoDia}
          />
        </Grid>
      </div>
    </Grid>
  );
};

const PassageirosDashboard = ({
  campanha,
  campanhaCidade,
  totalPassageiros,
  totalCorridas,
  periodoSelecionado,
  corridasPeriodos,
  meses,
  corridasDiaSemana,
  corridasPeriodoDia,
  periodosLoading,
  corridasPeriodoDiaLoading,
}) => {
  const [passageirosMediaPeriodo, setPassageirosMediaPeriodo] = useState(0);
  const [passageirosMediaDia, setPassageirosMediaDia] = useState(0);
  const [corridasMediaPeriodo, setCorridasMediaPeriodo] = useState(0);
  const [corridasMediaDia, setCorridasMediaDia] = useState(0);

  useEffect(() => {
    if (!campanha) return;

    const dias = Data.calculaDiasNoPeriodo(
      campanha.dataInicioVeiculacao,
      campanha.diasPeriodo,
      campanha.quantidadeMeses,
      periodoSelecionado?.index,
    );
    const periodos = periodoSelecionado?.index
      ? 1
      : Data.periodoDaCampanha(
          campanha.dataInicioVeiculacao,
          campanha.diasPeriodo,
          campanha.quantidadeMeses,
        );
    setCorridasMediaPeriodo(
      totalCorridas /
        periodos /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos),
    );
    setCorridasMediaDia(
      totalCorridas /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos) /
        (dias || 1),
    );

    setPassageirosMediaPeriodo(
      totalPassageiros /
        periodos /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos),
    );
    setPassageirosMediaDia(
      totalPassageiros /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos) /
        (dias || 1),
    );
  }, [
    campanha,
    campanhaCidade,
    periodoSelecionado,
    totalPassageiros,
    totalCorridas,
  ]);

  return (
    <Grid container>
      <GraficoTotais
        titulo="Corridas realizadas"
        totais={[
          {
            label: 'Total',
            value: `${Number.format(Number.safeParseInt(totalCorridas))}`,
          },
          {
            label: 'Média / Veículo / Período',
            value: `${Number.format(
              Number.safeParseInt(corridasMediaPeriodo),
            )}`,
          },
          {
            label: 'Média / Veículo / Dia',
            value: `${Number.format(Number.safeParseInt(corridasMediaDia))}`,
          },
        ]}
      />
      <GraficoBarras
        campanha={campanha}
        meses={meses}
        periodos={corridasPeriodos?.map((c) =>
          parseInt(Number.safeParseFloat(c)),
        )}
        periodoSelecionado={periodoSelecionado}
        titulo="Corridas realizadas"
        loading={periodosLoading}
      />
      <Grid style={{height: 40, width: '100%'}} />
      <GraficoTotais
        titulo="Passageiros transportados"
        totais={[
          {
            label: 'Total',
            value: `${Number.format(Number.safeParseInt(totalPassageiros))}`,
          },
          {
            label: 'Média / Veículo / Período',
            value: `${Number.format(
              Number.safeParseInt(passageirosMediaPeriodo),
            )}`,
          },
          {
            label: 'Média / Veículo / Dia',
            value: `${Number.format(Number.safeParseInt(passageirosMediaDia))}`,
          },
        ]}
      />
      <GraficoBarras
        campanha={campanha}
        meses={meses}
        periodos={corridasPeriodos?.map((c) =>
          parseInt(Number.safeParseFloat(c) * 1.4),
        )}
        periodoSelecionado={periodoSelecionado}
        titulo="Passageiros transportados"
        loading={periodosLoading}
      />
      <GraficoDiaSemana
        valorTotal={totalPassageiros}
        dias={corridasDiaSemana?.map((dia) => ({
          ...dia,
          valor: Number.safeParseFloat(dia.valor) * 1.4,
        }))}
        loading={corridasPeriodoDiaLoading}
        titulo="Passageiros"
      />
      <GraficoPeriodoDia
        campanha={campanha}
        periodos={corridasPeriodoDia?.map((periodo) => ({
          ...periodo,
          valor: Number.safeParseFloat(periodo.valor) * 1.4,
        }))}
        valorTotal={totalPassageiros}
        loading={corridasPeriodoDiaLoading}
        titulo="Passageiros"
      />
    </Grid>
  );
};

const styles = {
  graphsContainer: {
    overflowY: 'auto',
    padding: '16px',
    height: (theme) => theme.contentHeightMd,
  },
};

export default PassageirosEstimados;

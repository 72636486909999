import {useQuery} from '@apollo/client';
import {Box, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import InfoWindow from '../../google/InfoWindow';
import Map from '../../google/Map';
import Marker from '../../google/Marker';
import TransitLayer from '../../google/TransitLayer';
import {FIND_ALL_ANUNCIOS_DA_CAMPANHA} from '../../graphql/queries';
import Data from '../../utils/data';
import {getImageAuth} from '../../utils/get-image';
import {centroBrasil} from '../../utils/get-places';

const MapaMotoristas = ({campanha, campanhaCidade, selecionarAnuncio}) => {
  const [zoom, setZoom] = useState(4);

  const localizacoesQuery = useQuery(FIND_ALL_ANUNCIOS_DA_CAMPANHA, {
    variables: {
      pageable: {
        pageNumber: 0,
        sortDir: 'DESC',
        sortField: 'dataLancamento',
        pageSize: -1,
      },
      searchDTO: {
        anuncioSituacaoIn: [
          'ATIVO',
          'FINALIZADO',
          'AGUARDANDO_REMOCAO',
          'FINALIZAR',
        ],
        campanhaId: campanha?.id,
        campanhaCidadeId: campanhaCidade?.id || null,
      },
    },
  });

  const coordenadasCidade = {
    lat: campanhaCidade?.cidade?.latitude || centroBrasil.lat,
    lng: campanhaCidade?.cidade?.longitude || centroBrasil.lng,
  };
  const [centerMapLatLng, setCenterMapLatLng] = useState(coordenadasCidade);
  const [transitLayerEnabled] = useState(false);
  const [localizacoes, setLocalizacoes] = useState([]);
  const [infoWindowSettings, setInfoWindowSettings] = useState({
    visible: false,
    motorista: null,
    marker: null,
    map: null,
  });

  useEffect(() => {
    if (
      !campanhaCidade?.cidade?.latitude ||
      !campanhaCidade?.cidade?.longitude
    ) {
      setZoom(4);
      return setCenterMapLatLng({
        lat: centroBrasil.lat,
        lng: centroBrasil.lng,
      });
    }

    setZoom(13);
    return setCenterMapLatLng({
      lat: campanhaCidade?.cidade?.latitude,
      lng: campanhaCidade?.cidade?.longitude,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaCidade]);

  useEffect(() => {
    setLocalizacoes([]);
    if (!localizacoesQuery.loading) {
      setLocalizacoes(
        (localizacoesQuery.data?.anuncios?.content || [])
          .filter((l) => !!l.localizacaoAtual)
          .map((item) => ({
            ...item.localizacaoAtual,
            anuncio: item,
          })),
      );
    }
  }, [localizacoesQuery]);

  const motoristaInfoWindow =
    infoWindowSettings && infoWindowSettings.motorista
      ? infoWindowSettings.motorista
      : null;

  const formatarDataLocalizacao = (data) =>
    Data.dataFormat(data, 'DD/MM/YYYY [às]HH[h]mm');

  useEffect(() => {
    if (localizacoes) {
      const centerMap = () => {
        const localizacao = localizacoes.find((l) => !!l);

        if (localizacao && localizacao.latitude && localizacao.longitude) {
          return {lat: localizacao.latitude, lng: localizacao.longitude};
        } else if (localizacao && localizacao.lat && localizacao.lng) {
          return {lat: localizacao.lat, lng: localizacao.lng};
        }
        return centerMapLatLng;
      };
      const newCenterMapLatLng = centerMap();
      if (
        centerMapLatLng.lat !== newCenterMapLatLng.lat &&
        centerMapLatLng.lng !== newCenterMapLatLng.lng
      ) {
        setCenterMapLatLng(centerMap());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizacoes, centerMapLatLng]);

  return (
    <Grid container item xs={12} height={'100%'}>
      <Map
        zoom={zoom}
        center={centerMapLatLng}
        locations={localizacoes}
        options={{mapTypeControl: false}}>
        <TransitLayer enabled={transitLayerEnabled} />
        <InfoWindow {...infoWindowSettings}>
          <div className="iw-condutor-container">
            <div className="iw-condutor-avatar">
              <Box
                component="img"
                src={getImageAuth(motoristaInfoWindow?.fotoPerfil)}
                onError={() =>
                  setInfoWindowSettings({
                    ...infoWindowSettings,
                    motorista: {
                      ...motoristaInfoWindow,
                      fotoPerfil: null,
                    },
                  })
                }
                alt="avatar"
                sx={styles.avatar}
              />
            </div>
            <div className="iw-condutor-info">
              <div className="iw-condutor-info-nome">
                <label>Condutor</label>
                <p>{motoristaInfoWindow?.nome}</p>
              </div>
              <div className="iw-condutor-info-posicao">
                <label>Última posição</label>
                <p>
                  {formatarDataLocalizacao(motoristaInfoWindow?.data) || null}
                </p>
              </div>
            </div>
          </div>
        </InfoWindow>
        {localizacoes &&
          localizacoes.map((localizacao, index) => {
            const {anuncio = {}} = localizacao;
            const {motorista = {}} = anuncio;
            return (
              <Marker
                key={index}
                active={false}
                position={{
                  lat: localizacao.latitude,
                  lng: localizacao.longitude,
                }}
                events={{
                  onClick: () => {selecionarAnuncio(anuncio)},
                  onMouseOver: (map, marker) => {
                    setInfoWindowSettings({
                      visible: true,
                      marker,
                      map,
                      motorista: {
                        ...motorista,
                        data: localizacao.data || null,
                      },
                    });
                  },
                  onMouseOut: () => {
                    setInfoWindowSettings({
                      visible: false,
                      motorista: null,
                      marker: null,
                      map: null,
                    });
                  },
                }}
              />
            );
          })}
      </Map>
    </Grid>
  );
};

const styles = {
  avatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
};

export default MapaMotoristas;

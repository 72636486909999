const campanhaSituacao = {
  AGUARDANDO_APROVACAO: 'Aguardando aprovação',
  NAO_APROVADA: 'Não aprovada',
  AGUARDANDO_IMPRESSAO: 'Aguardando impressão',
  ATIVA: 'Ativa',
  ATIVA_PARCIAL: 'Ativa',
  PENDENTE: 'Pendente',
  SUSPENSA: 'Suspensa',
  CANCELADA: 'Cancelada',
  FINALIZADA: 'Finalizada'
};

const anuncioSituacao = {
  AGUARDANDO_TROCA_MOTORISTA: 'Aguardando troca de motorista',
  AGUARDANDO_ACEITE_MOTORISTA: 'Aguardando aceite do motorista',
  SUSPENSO: 'Suspenso',
  AGUARDANDO_IMPRESSAO: 'Aguardando impressão',
  AGUARDANDO_AGENDAMENTO_INSTALACAO: 'Aguardando agendamento da instalação',
  AGUARDANDO_ACEITE_AGENDAMENTO_INSTALACAO: 'Aguardando aceite do agendamento da instalação',
  AGUARDANDO_INSTALACAO: 'Aguardando instalação',
  AGUARDANDO_IMAGEM_INSTALACAO: 'Aguardando imagem de instalção',
  ATIVO: 'Ativo',
  AGUARDANDO_REMOCAO: 'Aguardando remoção',
  FINALIZADO: 'Finalizado'
};

const imagemAnuncioTipo = {
  INSTALACAO: 'Instalação',
  MENSAL: 'Mensal',
  REMOCAO: 'Remoção'
};

export {
  campanhaSituacao,
  anuncioSituacao,
  imagemAnuncioTipo
}

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Box, Grid, Typography} from '@mui/material';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Data from '../../utils/data';
import {getImageAuth} from '../../utils/get-image';

import '../../utils/string';
import 'moment/locale/pt-br';
import ImagensCampanha from './imagens';

const Divisor = require('../../assets/images/divisor-relatorio.png');
const AvatarImg = require('../../assets/images/avatar-default.png');

const ImpressaoImagensCampanha = ({
  campanha = {},
  campanhaCidade,
  periodos = [],
  periodoSelecionado,
}) => {
  const [avatar, setAvatar] = useState(undefined);

  const stringPeriodoReferencia = () => {
    let dataInicio = moment(campanha?.dataInicioVeiculacao);
    let dataFim = Data.dataAtualOuFinalCampanha(
      campanha?.dataInicioVeiculacao,
      campanha?.diasPeriodo,
      campanha?.quantidadeMeses,
    );

    if (periodoSelecionado) {
      dataInicio = moment(periodoSelecionado.inicio);
      dataFim = moment(periodoSelecionado.fim);
    }

    return `${dataInicio.format('DD/MM/YY')} até ${dataFim.format('DD/MM/YY')}`;
  };

  useEffect(() => {
    setAvatar(getImageAuth(campanha?.bannerCampanha));
  }, [campanha]);

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid
        sx={styles.reportHeader}
        className="report-header"
        style={{display: 'none'}}>
        <Grid item xs={12} style={{padding: '0 10px'}}>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '10px 0'}}
            src={Divisor}
          />
        </Grid>
        <Grid item xs={12} sx={styles.reportHeaderContent}>
          <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
            <Box
              component="img"
              src={avatar}
              alt={campanha?.titulo || ''}
              onError={() => setAvatar(AvatarImg)}
              sx={styles.avatar}
            />
          </Grid>
          <Grid item xs={8} style={{height: '100px'}}>
            <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
              Titulo: {campanha?.titulo || ''}
            </Typography>
            <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
              Cliente: {campanha?.anunciante?.nomeRazao || ''}
            </Typography>
            <Typography
              sx={styles.titleReport}
              style={{fontSize: 14, color: '#657496'}}>
              Cidades:{' '}
              {campanhaCidade?.cidade?.nomeEUf ||
                campanha.cidades
                  ?.map((c) => c.cidade?.nomeEUf || '')
                  .join(' - ')}
            </Typography>
            <Typography
              sx={styles.titleReport}
              style={{fontSize: 14, color: '#657496'}}>
              Período de Referência: {stringPeriodoReferencia()}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <KmMidiaLogo style={{width: '100%'}} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.observacaoContainer}>
          {!!campanha.observacoesRelatorios && (
            <pre>{`Observações: ${campanha.observacoesRelatorios}`}</pre>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={styles.periodosContainer}
        className="report-header"
        style={{display: 'none'}}>
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row'}}>
          {!periodoSelecionado && (
            <>
              <Grid item>
                <Typography sx={styles.periodosTitle}>Períodos:</Typography>
              </Grid>
              <Grid container item style={{display: 'flex'}}>
                {periodos.map((periodo, index) => (
                  <Typography key={index} sx={styles.periodos}>
                    {`P${index + 1}: ${periodo.inicio.format(
                      'DD/MM/YYYY',
                    )} - ${periodo.fim.format('DD/MM/YYYY')}`}
                  </Typography>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid sx={styles.imagensContainer} style={{paddingTop: 320}}>
        <ImagensCampanha
          campanha={campanha}
          campanhaCidade={campanhaCidade}
          periodo={periodoSelecionado?.index}
        />
      </Grid>
    </Grid>
  );
};

const styles = {
  observacaoContainer: {
    breakInside: 'avoid',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'start',
    margin: '5px 0',
    paddingLeft: '50px',
    fontSize: 12,
  },
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 5px',
    width: '297mm',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    padding: '0px 10px',
    position: 'fixed',
    width: 'inherit',
    top: '5px',
    left: 0,
    backgroundColor: '#fff',
  },
  reportHeaderContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '20px',
    paddingRight: '20px',
  },
  periodosTitle: {
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    margin: '2px',
    marginLeft: '0px',
  },
  periodos: {
    whiteSpace: 'nowrap',
    margin: '2px 5px',
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    background: '#e8e8e8',
    borderRadius: '20px',
    padding: '0 10px',
  },
  imagensContainer: {
    '& img': {
      objectFit: 'scale-down !important',
    },
  },
  avatar: {
    background: '#d2d7e2',
    objectFit: 'contain',
    backgroundColor: '#fff',
    width: '80px',
    height: '80px',
    display: 'block',
    borderRadius: '50%',
    marginTop: '16px',
    marginBottom: '10px',
  },
};

export default ImpressaoImagensCampanha;

import React from 'react';
import {FIND_ALL_CAMPANHA as query} from '../../graphql/queries';
import {SelectBase} from '../../components/select/mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map(item => ({
      ...item,
      label: item.titulo,
      value: item.id
    }))
    : [];
};

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 20}) => {
  return {
    pageable: {
      pageNumber,
      pageSize,
      orderBy: [
        {
          campo: 'situacao',
          ordem: 'ASC',
        },
        {
          campo: 'dataInicioVeiculacao',
          ordem: 'DESC',
        },
      ],
    },
    searchDTO: {
      ...searchDTO,
      campanhaSituacoesIn: ['ATIVA', 'FINALIZADA'],
      search
    }
  };
};

export const SelectCampanhas = (props) => {
  return (
    <SelectBase
      label="Campanha"
      responseObjectName="campanhas"
      transformOptions={transformOptions}
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};

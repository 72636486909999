import {LIGHT_COLORS as colors} from './colors/mui-colors';

export const iconsButtonStyle = {
  normal: {
    background: '#fff',
    color: '#fff !important',
    margin: '0 5px !important',
    '&:disabled': {
      opacity: 0.2,
    },
  },
  primary: {
    background: `${colors.primary} !important`,
    color: '#fff !important',
    margin: '0 5px !important',
    '&:hover': {
      background: `${colors.primaryHover}`,
      boxShadow: '4.79602px 6.3947px 20.7828px rgba(0, 131, 230, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  cancel: {
    background: `${colors.cancel} !important`,
    color: '#fff !important',
    margin: '0 5px !important',
    '&:hover': {
      background: `${colors.cancelHover} !important`,
      boxShadow: '3.15px 6.3px 20.475px rgba(255, 82, 82, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  gray: {
    background: `${colors.blueGraySecundary} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.blueGraySecundaryHover} !important`,
      boxShadow: '3.12338px 6.24675px 20.3019px rgba(207, 210, 217, 0.8)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  green: {
    background: `${colors.green} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.greenHover} !important`,
      boxShadow: '4.81395px 6.4186px 20.8605px rgba(16, 197, 113, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  greenSecundary: {
    background: `${colors.greenSecundary} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.greenSecundaryHover} !important`,
      boxShadow: '3px 4px 13px rgba(19, 139, 119, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  greenTertiary: {
    background: `${colors.greenTertiary} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.greenTertiaryHover} !important`,
      boxShadow: '3px 4px 13px rgba(85, 171, 35, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  yellow: {
    background: `${colors.yellow} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.yellowHover} !important`,
      boxShadow: '3px 4px 13px rgba(255, 186, 90, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
  purple: {
    background: `${colors.purple} !important`,
    color: '#fff !important',
    margin: '0px 5px !important',
    '&:hover': {
      background: `${colors.purpleHover} !important`,
      boxShadow: '3px 4px 13px rgba(123, 97, 255, 0.5)',
    },
    '&:disabled': {
      opacity: 0.2,
    },
  },
};

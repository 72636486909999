import config from '../config/config';
import AvatarImg from '../assets/images/avatar-default.png';

export const getImageAuth = idFoto => {
  const Auth = JSON.parse(window.localStorage.getItem('auth') || '{}');

  if (Auth.access_token) {
    return idFoto ? `${config.AMAZON_S3_BASE_URL}${idFoto}?access_token=${Auth.access_token}` : AvatarImg;
  } else {
    return '';
  }
};

import gql from 'graphql-tag';

export const ATUALIZAR_ANUNCIANTE = gql`
  mutation AtualizarDadosAnunciante($anunciante: AnuncianteInput) {
    atualizarDadosAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;

export const ATUALIZAR_SENHA = gql`
  mutation AtualizaSenha($novaSenha: String, $senhaAtual: String) {
    trocarSenha(novaSenha: $novaSenha, senhaAtual: $senhaAtual) {
      tokenClienteRegistrado
    }
  }
`;

export const CRIAR_EMAIL_CONTATO = gql`
  mutation criarEmailContato(
    $mensagem: String
    $telefone: String
    $titulo: String
    $email: String
    $tipoDuvida: TipoEmailContato
  ) {
    createEmailContato(
      emailContato: {
        mensagem: $mensagem
        telefone: $telefone
        titulo: $titulo
        email: $email
        tipoDuvida: $tipoDuvida
      }
    ) {
      id
    }
  }
`;

import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Grid} from '@mui/material';
import {AtualizaPerfilContainer} from '../containers/atualiza-perfil';
import {RedefinirSenhaContainer} from '../containers/redefinir-senha';
import {ANUNCIANTE_LOGADO, TOTAL_CAMPANHA_POR_CIDADE} from '../graphql/queries';
import MapaCidades from '../containers/mapas/mapa-cidades';

const Perfil = () => {
  const [anunciante, setAnunciante] = useState(undefined)
  const [localizacoes, setLocalizacoes] = useState([]);
  const [redefinirSenha, setRedefinirSenha] = useState(false);

  const anuncianteQuery = useQuery(ANUNCIANTE_LOGADO);
  const localizacoesQuery = useQuery(TOTAL_CAMPANHA_POR_CIDADE);

  useEffect(() => {
    if (localizacoesQuery.loading || localizacoesQuery.error) return;

    setLocalizacoes(localizacoesQuery.data.totais || []);
  }, [localizacoesQuery]);

  useEffect(() => {
    if (anuncianteQuery.loading || anuncianteQuery.error) return;

    setAnunciante(anuncianteQuery.data?.anuncianteLogado || {});
  }, [anuncianteQuery]);

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={4} md={3} sx={styles.infoContainer}>
        {anunciante && redefinirSenha && (
          <RedefinirSenhaContainer
            anunciante={anunciante}
            onClose={() => setRedefinirSenha(false)}
          />
        )}
        {anunciante && !redefinirSenha && ((
          <AtualizaPerfilContainer
            anunciante={anunciante}
            onClickRedefinirSenha={() => setRedefinirSenha(true)}
          />
        ))}
      </Grid>
      <Grid item xs={8} md={9}>
        <MapaCidades sx={styles.mapa} localizacoes={localizacoes} />
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    height: '100%',
    width: '100%'
  },
  infoContainer: {
    backgroundColor: theme => theme.palette.paterns.white,
    boxShadow: '0px 6px 12px rgb(0 0 0 / 20%)',
    zIndex: 2
  },
  mapa: {
    height: 'calc(100vh - 61px)'
  }
};

export default Perfil;

import {FormatListBulletedRounded, PlaceRounded} from '@mui/icons-material';
import {Grid, Paper, ToggleButton, ToggleButtonGroup} from '@mui/material';
import React, {useState} from 'react';
import MapaMotoristas from '../../containers/mapas/mapa-motoristas';
import DetalhesMotorista from '../../containers/modais/detalhes-motorista';
import ListaMotoristas from '../../containers/motoristas/lista-motoristas';

const MotoristasCampanha = ({campanha, campanhaCidade}) => {
  const [tipo, setTipo] = useState('mapa');
  const [anuncioSelecionado, setAnuncioSelecionado] = useState(undefined);

  if (!campanha) {
    return <Grid container sx={styles.container} />;
  }

  return (
    <Grid
      container
      sx={styles.container}
      alignContent={'baseline'}
      component={Paper}>
      <Grid container sx={styles.selecaoTipo}>
        <ToggleButtonGroup
          value={tipo}
          exclusive
          onChange={(e, value) => setTipo(value)}
          aria-label="visualização"
          sx={styles.tipoSelect}>
          <ToggleButton value="mapa" aria-label="mapa" sx={styles.tipoButton}>
            <PlaceRounded />
            Mapa
          </ToggleButton>
          <ToggleButton value="lista" aria-label="lista" sx={styles.tipoButton}>
            <FormatListBulletedRounded />
            Lista
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {tipo === 'mapa' && (
        <MapaMotoristas
          campanha={campanha}
          campanhaCidade={campanhaCidade}
          selecionarAnuncio={setAnuncioSelecionado}
        />
      )}
      {tipo === 'lista' && (
        <ListaMotoristas
          campanha={campanha}
          campanhaCidade={campanhaCidade}
          selecionarAnuncio={setAnuncioSelecionado}
        />
      )}
      <DetalhesMotorista
        openModal={!!anuncioSelecionado}
        onClose={() => setAnuncioSelecionado(undefined)}
        anuncio={anuncioSelecionado}
      />
    </Grid>
  );
};

const styles = {
  container: {
    position: 'relative',
    borderRadius: '6px',
    backgroundColor: (theme) => theme.palette.paterns.white,
    padding: '0',
    cursor: 'default',
    overflow: 'hidden',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  selecaoTipo: {
    position: 'absolute',
    zIndex: 2,
  },
  tipoSelect: {
    backgroundColor: (theme) => theme.palette.paterns.white,
    borderRadius: '50px',
    padding: '3px',
    margin: '8px',
  },
  tipoButton: {
    border: 'none',
    height: '25px',
    borderRadius: '50px !important',
    textTransform: 'none',
    fontFamily: 'AktivGrotesk-Bold',
    color: (theme) => theme.palette.paterns.black,
    '&.Mui-selected': {
      backgroundColor: (theme) => theme.palette.paterns.blueGraySecundary,
    },
    svg: {
      width: '16px',
      height: '16px',
      marginRight: '2px',
      marginBottom: '1px',
    },
  },
};

export default MotoristasCampanha;

import React from "react";
import {CircularProgress, Grid, Paper, Typography} from "@mui/material";
import Data from "../../utils/data";
import Number from "../../utils/number";

const DetalhesCampanha = ({campanha, campanhaCidade}) => {

  if (!campanha) {
    return (
      <Grid container sx={styles.container} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress sx={styles.loading} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={styles.container}
      alignContent={"baseline"}
      component={Paper}
    >
      <Grid item xs={12}>
        <Typography sx={styles.titulo}>Dados da campanha</Typography>
      </Grid>
      {campanhaCidade && (
        <React.Fragment>
          <Grid item xs={7}>
            <Typography sx={styles.label}>População:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={styles.valor}>{Number.format(campanhaCidade.cidade?.populacao)}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography sx={styles.label}>Densidade:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={styles.valor}>{Number.format(campanhaCidade.cidade?.densidadeDemografica)} hab/m²</Typography>
          </Grid>
        </React.Fragment>
      )}
      {!campanhaCidade && (
        <React.Fragment>
          <Grid item xs={7}>
            <Typography sx={styles.label}>Cidades:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={styles.valor}>{Number.format(campanha.cidades?.length)}</Typography>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={7}>
        <Typography sx={styles.label}>Motoristas:</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={styles.valor}>{Number.format(campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos)}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography sx={styles.label}>Duração:</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={styles.valor}>{campanha.quantidadeMeses * campanha.diasPeriodo} dias</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography sx={styles.label}>Início:</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={styles.valor}>{Data.dataFormat(campanha.dataInicioVeiculacao)}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography sx={styles.label}>Término:</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={styles.valor}>{Data.dataFinalCampanha(campanha.dataInicioVeiculacao, campanha.diasPeriodo, campanha.quantidadeMeses, true)}</Typography>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    borderRadius: '6px',
    backgroundColor: theme => theme.palette.paterns.white,
    padding: '8px 16px',
    height: '100%',
    cursor: 'default',
    boxShadow: '1px 6px 12px rgb(128 152 213 / 20%)',
  },
  loading: {
    width: '20px !important',
    height: '20px !important',
  },
  titulo: {
    fontFamily: 'AktivGrotesk-Bold',
    marginBottom: '6px',
    fontSize: '14px'
  },
  label: {
    fontFamily: 'AktivGrotesk-Light',
    color: theme => theme.palette.paterns.blueGray,
    fontSize: '12px'
  },
  valor: {
    fontFamily: 'AktivGrotesk-Medium',
    color: theme => theme.palette.paterns.black,
    fontSize: '12px',
  }
};

export default DetalhesCampanha;
import {Box, Grid, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import GraficoBarras from '../../components/graficos/grafico-barras';
import GraficoDiaSemana from '../../components/graficos/grafico-dia-semana';
import GraficoHeatmap from '../../components/graficos/grafico-heatmap';
import GraficoPeriodoDia from '../../components/graficos/grafico-periodo-dia';
import GraficoTotais from '../../components/graficos/grafico-totais';
import Data from '../../utils/data';
import {getImageAuth} from '../../utils/get-image';
import Number from '../../utils/number';

const Divisor = require('../../assets/images/divisor-relatorio.png');
const AvatarImg = require('../../assets/images/avatar-default.png');

const ImpressaoImpactos = ({
  campanha = {},
  campanhaCidade = {},
  periodos = [],
  periodoSelecionado,
  totalImpactados,
  custoPorImpacto,
  impactosHeatmap,
  impactosDiaSemana,
  impactosPeriodoDia,
  impactosPeriodos,
  meses,
}) => {
  const [avatar, setAvatar] = useState(undefined);
  const [impactosMediaPeriodo, setImpactosMediaPeriodo] = useState(0);
  const [impactosMediaDia, setImpactosMediaDia] = useState(0);

  const stringPeriodoReferencia = () => {
    let dataInicio = moment(campanha?.dataInicioVeiculacao);
    let dataFim = Data.dataAtualOuFinalCampanha(
      campanha?.dataInicioVeiculacao,
      campanha?.diasPeriodo,
      campanha?.quantidadeMeses,
    );

    if (periodoSelecionado) {
      dataInicio = moment(periodoSelecionado.inicio);
      dataFim = moment(periodoSelecionado.fim);
    }

    return `${dataInicio.format('DD/MM/YY')} até ${dataFim.format('DD/MM/YY')}`;
  };

  useEffect(() => {
    setAvatar(getImageAuth(campanha?.bannerCampanha));
  }, [campanha]);

  useEffect(() => {
    if (!campanha) return;

    const dias = Data.calculaDiasNoPeriodo(
      campanha.dataInicioVeiculacao,
      campanha.diasPeriodo,
      campanha.quantidadeMeses,
      periodoSelecionado?.index,
    );
    const periodos = periodoSelecionado
      ? 1
      : Data.periodoDaCampanha(
          campanha.dataInicioVeiculacao,
          campanha.diasPeriodo,
          campanha.quantidadeMeses,
        );

    setImpactosMediaPeriodo(
      totalImpactados /
        periodos /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos),
    );
    setImpactosMediaDia(
      totalImpactados /
        (campanhaCidade?.quantidadeVeiculos || campanha.quantidadeVeiculos) /
        (dias || 1),
    );
  }, [campanha, campanhaCidade, periodoSelecionado, totalImpactados]);

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid
        sx={styles.reportHeader}
        className="report-hidden"
        style={{display: 'none'}}>
        <Grid item xs={12} style={{padding: '0 10px'}}>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '10px 0'}}
            src={Divisor}
          />
        </Grid>
        <Grid item xs={12} sx={styles.reportHeaderContent}>
          <Grid item xs={2} style={{display: 'flex', justifyContent: 'center'}}>
            <Box
              component="img"
              src={avatar}
              alt={campanha?.titulo || ''}
              onError={() => setAvatar(AvatarImg)}
              sx={styles.avatar}
            />
          </Grid>
          <Grid item xs={8} style={{height: '100px'}}>
            <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
              Titulo: {campanha?.titulo || ''}
            </Typography>
            <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
              Cliente: {campanha?.anunciante?.nomeRazao || ''}
            </Typography>
            <Typography
              sx={styles.titleReport}
              style={{fontSize: 14, color: '#657496'}}>
              Cidades:{' '}
              {campanhaCidade?.cidade?.nomeEUf ||
                campanha.cidades
                  ?.map((c) => c.cidade?.nomeEUf || '')
                  .join(' - ')}
            </Typography>
            <Typography
              sx={styles.titleReport}
              style={{fontSize: 14, color: '#657496'}}>
              Período de Referência: {stringPeriodoReferencia()}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <KmMidiaLogo style={{width: '100%'}} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.observacaoContainer}>
          {!!campanha.observacoesRelatorios && (
            <pre>{`Observações: ${campanha.observacoesRelatorios}`}</pre>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={styles.periodosContainer}
        className="report-hidden"
        style={{display: 'none'}}>
        <Grid item xs={12} style={{display: 'flex', flexDirection: 'row'}}>
          {!periodoSelecionado && (
            <>
              <Grid item>
                <Typography sx={styles.periodosTitle}>Períodos:</Typography>
              </Grid>
              <Grid container item style={{display: 'flex'}}>
                {periodos.map((periodo, index) => (
                  <Typography key={index} sx={styles.periodos}>
                    {`P${index + 1}: ${periodo.inicio.format(
                      'DD/MM/YYYY',
                    )} - ${periodo.fim.format('DD/MM/YYYY')}`}
                  </Typography>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid sx={styles.graphContainer} style={{paddingTop: 320}}>
        <GraficoTotais
          titulo="Impactos estimados"
          totais={[
            {
              label: 'Total',
              value: `${Number.format(Number.safeParseInt(totalImpactados))}`,
            },
            {
              label: 'Média / Veículo / Período',
              value: `${Number.format(
                Number.safeParseInt(impactosMediaPeriodo),
              )}`,
            },
            {
              label: 'Média / Veículo / Dia',
              value: `${Number.format(Number.safeParseInt(impactosMediaDia))}`,
            },
            {
              label: 'CPM',
              value: `${Number.currencyFormatDigitos(
                (custoPorImpacto || 0) * 1000,
                4,
              )}`,
            },
            {
              label: 'Custo individual',
              value: `${Number.currencyFormatDigitos(custoPorImpacto, 4)}`,
            },
          ]}
        />
        <GraficoBarras
          campanha={campanha}
          meses={meses}
          periodos={impactosPeriodos}
          periodoSelecionado={periodoSelecionado}
          titulo="Impactos estimados"
          containerMinHeight={'25vh'}
          grafHeight={200}
        />
      </Grid>
      <Grid sx={styles.graphPizzaContainer} style={{breakBefore: 'always'}}>
        <GraficoDiaSemana
          valorTotal={totalImpactados}
          dias={impactosDiaSemana}
          titulo="Impactos estimados"
        />
        <GraficoPeriodoDia
          campanha={campanha}
          periodos={impactosPeriodoDia}
          valorTotal={totalImpactados}
          titulo="Impactos estimados"
        />
      </Grid>
      <Grid sx={styles.graphContainer} style={{paddingTop: 320}}>
        <GraficoHeatmap
          campanha={campanha}
          heatmap={impactosHeatmap}
          titulo="Impactos estimados"
          cellHeight={90}
          cellValueColor="#000"
        />
      </Grid>
      <Grid
        sx={styles.footer}
        className="report-hidden"
        style={{display: 'none'}}>
        <Grid item xs={12} sx={styles.footerLogoContainer}>
          <KmMidiaLogo style={styles.footerLogo} />
          <Typography sx={styles.footerText}>
            Valorizando sua marca a cada KM rodado!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{padding: '0px'}}>
          <img
            alt={'divisor'}
            style={{width: '100%', margin: '5px 0'}}
            src={Divisor}
          />
        </Grid>
        <Typography style={{fontSize: 12}}>
          Relatório foi gerado no dia{' '}
          {moment().format('DD [de] MMMM [de] YYYY [às] HH[h]mm')}, válido por
          30 dias
        </Typography>
        <Typography style={{fontSize: 12}}>{`© Copyright ${moment().format(
          'YYYY',
        )}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}</Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  graphContainer: {
    breakInside: 'avoid',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    '& canvas': {
      position: 'absolute',
      left: 0,
      top: 20,
      minWidth: 1050,
      maxHeight: '200px',
    },
  },
  graphPizzaContainer: {
    breakInside: 'avoid',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    margin: '5px 0',
  },
  observacaoContainer: {
    breakInside: 'avoid',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'start',
    margin: '5px 0',
    paddingLeft: '50px',
    fontSize: 12,
  },
  reportContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 5px',
    width: '297mm',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    padding: '0px 10px',
    position: 'fixed',
    width: 'inherit',
    top: '5px',
    left: 0,
    backgroundColor: '#fff',
  },
  reportHeaderContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '20px',
    paddingRight: '20px',
  },
  footer: {
    padding: '0px 20px',
    position: 'fixed',
    width: 'inherit',
    top: '1550px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerLogo: {
    height: '25px',
    width: '150px',
    margin: '6px 20px 0px -5px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  periodosTitle: {
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    margin: '2px',
    marginLeft: '0px',
  },
  periodos: {
    whiteSpace: 'nowrap',
    margin: '2px 5px',
    fontSize: 12,
    fontWeight: '700',
    color: '#657496',
    background: '#e8e8e8',
    borderRadius: '20px',
    padding: '0 10px',
  },
  periodosContainer: {
    top: 280,
    position: 'absolute',
    padding: '2px 2px 2px 20px',
  },
  avatar: {
    background: '#d2d7e2',
    objectFit: 'contain',
    backgroundColor: '#fff',
    width: '80px',
    height: '80px',
    display: 'block',
    borderRadius: '50%',
    marginTop: '16px',
    marginBottom: '10px',
  },
};

export default ImpressaoImpactos;

import React from 'react';
import {useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import DetalhesCampanha from './detalhes-campanha';
import QuilometrosCampanha from './quilometros-campanha';
import ImpactosCampanha from './impactos-campanha';
import PassageirosCampanha from './passageiros-campanha';
import MotoristasCampanha from './motoristas-campanha';

const Home = () => {
  const {campanhaSelected, cidadeSelected} = useSelector((store) => store.Toolbar);

  return (
    <Grid id="page-container" container>
      <Grid container item xs={12} spacing={1} sx={styles.detalhesContainer}>
        <Grid item xs={4.5}>
          <DetalhesCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
        </Grid>
        <Grid item xs={2.5}>
          <QuilometrosCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
        </Grid>
        <Grid item xs={2.5}>
          <ImpactosCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
        </Grid>
        <Grid item xs={2.5}>
          <PassageirosCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={styles.motoristasContainer}>
        <MotoristasCampanha campanha={campanhaSelected} campanhaCidade={cidadeSelected} />
      </Grid>
    </Grid>
  );
};

const styles = {
  detalhesContainer: {
    marginBottom: '8px',
    height: '167px'
  },
  motoristasContainer: {
    minHeight: 'calc(100vh - 243px)'
  }
};

export default Home;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Field, Formik} from 'formik';
import {useQuery} from '@apollo/client';
import {AppBar, Avatar, Button, Grid, Modal, Paper} from '@mui/material';
import {ArrowDropDownRounded, PersonRounded} from '@mui/icons-material';
import {logout} from '../redux/Auth';
import VideoModal from './video-modal';
import {CONTATO_ROUTE, PERFIL_ROUTE} from '../constants/routes';
import {SelectCampanhas} from '../containers/selects/mui-campanhas';
import {SelectCampanhaCidades} from '../containers/selects/mui-campanha-cidades';
import {setCampanhaSelected, setCidadeSelected} from '../redux/Toolbar/slice';
import {FIND_ALL_CAMPANHA} from '../graphql/queries';
import {getImageAuth} from '../utils/get-image';

export const ToolBarComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {name: anuncianteNome, photo} = useSelector((store) => store.Auth);
  const {campanhaSelected, cidadeSelected} = useSelector((store) => store.Toolbar);
  const fotoPerfil = photo && getImageAuth(photo);

  const campanhaQuery = useQuery(FIND_ALL_CAMPANHA, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 1,
        orderBy: [
          {
            campo: 'situacao',
            ordem: 'ASC',
          },
          {
            campo: 'dataInicioVeiculacao',
            ordem: 'DESC',
          },
        ],
      },
      searchDTO: {
        campanhaSituacoesIn: ['ATIVA', 'FINALIZADA'],
      }
    }
  })

  const [openVideo, setOpenVideo] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [campanha, setCampanha] = useState(campanhaSelected);
  const [cidade, setCidade] = useState(cidadeSelected);

  const handleClickLogout = async () => {
    dispatch(logout());
  };

  const handleRedirecionar = (rota) => {
    navigate(rota);
    setOpenMenu(false);
  }

  const handleOpenVideoTutorial = () => {
    setOpenVideo(true);
    setOpenMenu(false);
  };

  useEffect(() => {
    if (campanhaQuery.loading || campanhaQuery.errors || !!campanha) return;

    setCampanha(campanhaQuery.data?.campanhas?.content?.[0]);

  }, [campanhaQuery, campanha]);

  useEffect(() => {
    if (localStorage.tutorial) return;

    setOpenVideo(true);
    localStorage.setItem('tutorial', true);
  }, []);

  useEffect(() => {
    dispatch(setCampanhaSelected(campanha));
    dispatch(setCidadeSelected(cidade));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, cidade]);

  return (
    <AppBar sx={styles.appBarStyled} position="relative">
      <Formik initialValues={{campanha, cidade}} enableReinitialize>
        <Grid container justifyContent='space-between'>
          <Grid container item xs={7} spacing={2} sx={styles.selectsContainer}>
            <Grid item xs={6}>
              <Field
                component={SelectCampanhas}
                label="Campanha"
                name="campanha"
                onChange={e => {
                  setCampanha(e);
                  setCidade(undefined);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={(props) => <SelectCampanhaCidades searchDTO={{campanhaId: campanha?.id}} {...props} />}
                label="Cidade"
                name="cidade"
                onChange={setCidade}
                disabled={!campanha}
                isClearable
              />
            </Grid>
          </Grid>
          <Grid item xs={5} sx={styles.toolBarTools}>
            <Button sx={styles.perfilButton} title={anuncianteNome} onClick={() => setOpenMenu(o => !o)}>
              <Avatar
                src={fotoPerfil}
                alt={anuncianteNome}
                sx={styles.avatar}
              >
                <PersonRounded />
              </Avatar>
              <ArrowDropDownRounded />
            </Button>
          </Grid>
        </Grid>
      </Formik>
      <Modal
        componentsProps={{
          backdrop: {
            sx: {
              background: 'rgb(0, 0, 0, 0.0)',
            },
          },
        }}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
      >
        <Paper elevation={4} sx={styles.modal}>
          <Grid container style={{padding: '5px 0px'}}>
            <Button sx={styles.buttonMenu} onClick={() => handleRedirecionar(PERFIL_ROUTE)}>
              {'Editar perfil'}
            </Button>
            <Button sx={styles.buttonMenu} onClick={() => handleRedirecionar(CONTATO_ROUTE)}>
              {'Entrar em contato'}
            </Button>
            <Button sx={styles.buttonMenu} onClick={handleOpenVideoTutorial}>
              {'Video tutorial'}
            </Button>
            <Button sx={styles.buttonMenu} onClick={handleClickLogout}>
              {'Sair'}
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <VideoModal
        onClose={() => setOpenVideo(!openVideo)}
        openVideo={openVideo}
      />
    </AppBar>
  );
};

const styles = {
  appBarStyled: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#fff !important',
    width: '100% !important',
    height: (theme) => `${theme.toolbarHeight} !important`,
    zIndex: 5,
    padding: (theme) => `${theme.space[1]} 1rem`,
    boxShadow: ' -10px 6px 12px rgba(128, 152, 213, 0.2)',
    '& input': {
      height: '100%',
    },
  },
  toolBarTools: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    svg: {
      height: ' 100%',
    },
  },
  perfilButton: {
    cursor: 'pointer',
    border: 0,
    borderRadius: 0,
    background: 'transparent',
    padding: 0,
    display: 'flex',
    marginLeft: '25px',
    paddingLeft: '20px',
    borderLeft: '1px solid #8995B0',
    height: '40px',
    width: '85px',
    alignItems: 'center',
    overflow: 'hidden',
    'svg': {
      color: '#8995B0',
      width: '28px',
      height: '28px'
    }
  },
  modal: {
    width: '170px',
    height: 'fit-content',
    zIndex: 10,
    background: '#fff',
    position: 'absolute',
    right: '0px',
    top: theme => `calc(-12px + ${theme.toolbarHeight})`,
  },
  buttonMenu: {
    textTransform: 'none !important',
    justifyContent: 'flex-start !important',
    paddingLeft: '12px !important',
    width: '100%',
    borderRadius: '0px !important',
    color: '#8995B0 !important',
    '&:hover': {
      backgroundColor: '#8995B0 !important',
      color: '#fff !important'
    }
  },
  selectsContainer: {
    paddingTop: '6px',
    maxWidth: '700px !important'
  },
  avatar: {
    background: 'transparent',
    img: {
      objectFit: 'contain'
    }
  }
};

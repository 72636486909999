export const LOGIN_ROUTE = '/login';
export const ESQUECI_SENHA_ROUTE = '/esqueceu-sua-senha';
export const HOME_ROUTE = '/home';
export const QUILOMETRAGEM_ROUTE = '/quilometragem';
export const IMPACTOS_ROUTE = '/impactos';
export const PASSAGEIROS_ROUTE = '/passageiros';
export const IMAGENS_ROUTE = '/imagens';
export const ANUNCIOS_ROUTE = '/anuncios';
export const PERFIL_ROUTE = '/perfil';
export const CONTATO_ROUTE = '/contato';
export const MAPA_DE_CALOR_ROUTE = '/mapa-de-calor';

export const routeLabel = {
  [LOGIN_ROUTE]: 'Login',
  [ESQUECI_SENHA_ROUTE]: 'Esqueceu sua senha',
  [HOME_ROUTE]: 'Home',
  [QUILOMETRAGEM_ROUTE]: 'Quilometragem',
  [IMPACTOS_ROUTE]: 'Impactos',
  [PASSAGEIROS_ROUTE]: 'Passageiros',
  [IMAGENS_ROUTE]: 'Check-in / Check-out',
  [ANUNCIOS_ROUTE]: 'Anúncios',
  [PERFIL_ROUTE]: 'Perfil',
  [CONTATO_ROUTE]: 'Contato',
  [MAPA_DE_CALOR_ROUTE]: 'Mapa de calor',
};